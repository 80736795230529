import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext.js';

// LIBS
import { useTranslation } from 'react-i18next';
import '../i18n';

import Footer from '../components/footer.js';
import Loader from '../components/Loader.js';
import NavDashboard from '../components/NavDashboard.js';



// STYLES
import './Medias.css';

function Medias() {

  const { t, i18n } = useTranslation();

  const context = useContext(AuthContext);
  const [medias, setMedias] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [mediaSortOrder, setMediaSortOrder] = useState('desc');
  const [countryFilter, setCountryFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMedias = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BASEURL + '/api/category/count');
        const data = await response.json();
        console.log('data media', data);
        setMedias(data.category);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching medias:', error);
      }
    };

    fetchMedias();
  }, []);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleSort = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleCountryFilterChange = (event) => {
    setCountryFilter(event.target.value);
  };

  const sortedMedias = [...medias].sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.name.localeCompare(b.name);
    } else if (sortOrder === 'desc') {
      return b.name.localeCompare(a.name);
    } else if (mediaSortOrder === 'asc') {
      return a.mediaCount - b.mediaCount;
    } else {
      return b.mediaCount - a.mediaCount;
    }
  });

  const filteredMedias = sortedMedias.filter(
    (media) =>
      media.name.toLowerCase().includes(filter.toLowerCase()) &&
      (countryFilter === '' || media.country === countryFilter)
  );

  const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  

  if(isLoading){
    return(
      <Loader />
    )
  }

  if (context.isAuthenticate) {
    return (
      <>
        <div className="userDashboard">
          <div className="wrapConnect">
            <NavDashboard />
            <main id="UserDashboard">
              <h1>{t("Liste_des_medias")}</h1>
              <div className="filter">
                <label htmlFor="countryFilter">{t("Trier_par_pays")} : </label>
                <select id="countryFilter" value={countryFilter} onChange={handleCountryFilterChange}>
                  <option value="">{t("Tous_les_pays")}</option>
                  <option value="France">{t("France")}</option>
                  <option value="US" disabled>{t("etats_Unis")}</option>
                  <option value="BE" disabled>{t("Belgique")}</option>
                  <option value="CH" disabled>{t("Suisse")}</option>
                </select>
              </div>
              <table id="MediaTableCat" className="mediaTable">
                <thead>
                  <tr>
                    <th onClick={handleSort} style={{ cursor: 'pointer' }}>{t("Nom")}</th>
                    <th>{t("Description")}</th>
                    <th>{t("Nombre_de_medias")}</th>
                    <th>{t("Pays")}</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMedias.length ? (
                    filteredMedias.map((media) => (
                      <tr key={media._id}>
                        <td><Link to={`/medias/${media.name}`}>{capitalize(media.name)}</Link></td>
                        <td>{(media.description == "actualites_cat") ? t("actualites_cat") : media.description == "sport_cat" ? t("sport_cat") : media.description == "culture_car" ? t("culture_car") : media.description == "divertissement_cat" ? t("divertissement_cat") : media.description == "technologie_cat" ? t("technologie_cat") : media.description == "economie_cat" ? t("economie_cat") : media.description == "medias_cat" ? t("medias_cat") : media.description == "jeunesse_cat" ? t("jeunesse_cat") : media.description == "voyages_cat" ? t("voyages_cat") : media.description == "lifestyle_cat" ? t("lifestyle_cat") : media.description == "medias_inde" ? t("medias_inde") : t("Other")}</td>
                        <td>{media.mediaCount}</td>
                        <td>{media.country}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">
                        <Loader />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Medias;
