import {useEffect, useState, useContext} from 'react';
import {AuthContext} from "../../context/AuthContext.js"

import Loader from "../../components/Loader.js";

import "../AdminDashboard.css";
import "../Table.css";


function DashboardAdminUsers() {
  const context = useContext(AuthContext);

  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(true);




  useEffect(()=>{
    const headers = {};
    if(context.token){
      headers.Authorization = `Bearer ${context.token}`
    }
    fetch(process.env.REACT_APP_BASEURL + '/api/user',{
      headers: new Headers(headers)
    })
    .then((res)=>
      res.json()
    )
    .then((res)=>{
      if(res.success){
        setUsers(res.users);
        setIsLoading(false);
      }
    })
  },[]);


  const handleDeleteUser = (id) => {
    const headers = {};
    if(context.token){
      headers.Authorization = `Bearer ${context.token}`
    }
    setIsLoading(true);
    fetch(process.env.REACT_APP_BASEURL + `/api/user/delete/${id}`, {
      headers: new Headers(headers),
      method: 'DELETE',
    })
    .then((res)=>
      res.json()
    )
    .then((res)=>{
      if(res.success){
        let userTemp = [];
        userTemp = users.filter((user) => {
          if(user._id !== id) {
            return user
          }
        });
        setUsers([...userTemp]);
        setIsLoading(false);
      }
    })
  }
  const handleBanishUser = (id) => {
    const headers = {};
    if(context.token){
      headers.Authorization = `Bearer ${context.token}`
    }
    setIsLoading(true);
    fetch(process.env.REACT_APP_BASEURL + `/api/user/banish/${id}`, {
      headers: new Headers(headers),
      method: 'GET',
    })
    .then((res)=>
      res.json()
    )
    .then((res)=>{
      if(res.success){
        // let userTemp = [...res.user];
        // userTemp = userTemp.filter((user) => {
        //   if(user._id !== id) {
        //     return user
        //   }
        // });
        // setUsers([...userTemp]);
        // setIsLoading(false);
      }
    })
  }
    if(isLoading){
      return(
        <Loader />
      )
    }
    const lines = [];
    for (const [index, user] of users.entries()){
      lines.push(
        <tr key={index}>
          {(user.isAdmin)
            ? <td className="id" key={'id'+index}>{user._id}</td>
            : <td className="id" key={'id'+index}>{user._id} 
                <i onClick={() => handleDeleteUser(user._id)} className="fas fa-trash"></i>
                {(user.isBanned)
                  ?  <i onClick={() => handleBanishUser(user._id)} className="fas fa-ban" ></i>
                  :  <i onClick={() => handleBanishUser(user._id)} className="fas fa-check"></i>
                }
              </td>                                 
            }
          <td className="user" key={'user'+index}> {user.nickname}</td>
          <td className="email" key={'mail'+index}> {user.email}</td>
          {/* <td className="games">{user.games.map((game) =>(
            game
          ))}</td> */}
          <td className="montant" key={'selectedOption'+index}>{user.selectedOption}</td>
        </tr>
      )
    }
    return (
      <main className="AdminDashboard" id="AdminDashboardUsers">
        <div className="wrap vec">
          <h1>Users</h1>
          <p className="results">{users.length} result(s) found</p>


          <table cellPadding="0" cellSpacing="0" border="0">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Pseudo</th>
                  <th>Email</th>
                  <th>Games</th>
                  <th>Montant souhaité</th>
                </tr>
              </thead>
              <tbody>
                {lines}
              </tbody>
          </table>
        </div>
      </main>
    );
}

export default DashboardAdminUsers;
