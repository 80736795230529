import {useEffect, useState, useContext, useCallback} from 'react';
import {Link, useNavigate} from "react-router-dom";

import axios from "axios";

import {AuthContext} from "../context/AuthContext.js";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";

// STRIPE
import { loadStripe } from '@stripe/stripe-js';


// STYLES
import "./Subscription.css";

function Subscription() {
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState();
  const [userPremium, setUserPremium] = useState();

  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  function gestionAbonnement() {
    window.location.href = "https://billing.stripe.com/p/login/fZebLhb2o30W6K45kk";
  }
  
  async function handlePurchaseClick() {
    // Vérifiez si l'utilisateur a un stripeCustomerId
    if (!context.userInfos.stripeCustomerId) {
      // Si l'utilisateur n'a pas de stripeCustomerId, créez un client Stripe pour lui
      const response = await fetch(process.env.REACT_APP_BASEURL + `/api/user/createStripeCustomer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${context.token || localStorage.getItem('Token')}`
        },
        body: JSON.stringify({ email: context.userInfos.email })
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      const stripeCustomerId = data.stripeCustomerId;
      context.userInfos.stripeCustomerId = stripeCustomerId;
    }
  
    // Ouvrez la page de paiement Stripe
    window.open(`https://buy.stripe.com/dR6dT2dVL8vv9kQaEI?prefilled_email=${context.userInfos.email}&client_reference_id=${context.userInfos.id}`);
  }
  

  const context = useContext(AuthContext);
  
  useEffect(() => {
    setUser(context.userInfos);
    setUserPremium(context.userInfos.isPremium);
  }, [context.userInfos])

  if(context.isAuthenticate) {
    return (
      <>
        <div className="userDashboard">
          <div className="wrapConnect">
            <NavDashboard />
            <main id="UserDashboard">
              <h1>{t("Subscription")}</h1>
              <button onClick={gestionAbonnement}>{t("Manage_my_subscription")}</button><br/><br/>
              <div className="subscription">
                <div className="itemPriceFeatured">
                  <h3>{t("Gratuit")}</h3>
                  <ul>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Pack_de_credits_a_la_demande")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Acces_a_toutes_les_categories")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Plusieurs_contact_par_media")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Support_standard")}</li>
                    <li><i className="fa-regular fa-circle-xmark"></i> {t("Acces_multi_utilisateurs")}</li>
                    <li><i className="fa-regular fa-circle-xmark"></i> {t("Statistiques_avancees")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Envoye_par_PressPilot")} </li>
                  </ul>
                  <br/><p className='tac'>{t("Prix")} : 0€ {t("by_mois")}</p>
                  {userPremium ? (
                    <>
                    <br/><button className="ctaWhiteTall" onClick={gestionAbonnement}>{t("Acheter")}</button>
                    </>
                  ) : (
                    <>
                      <br /><button className="ctaGreenTall actualPlan">{t("actual_plan")}</button>
                    </>
                  )}
                </div>
                <div className="itemPriceFeatured">
                  <h3> {t("Startup")}</h3>
                  <ul>
                    <li><i className="fa-solid fa-circle-check"></i> 100 {t("credits_par_mois")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Acces_a_toutes_les_categories")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Plusieurs_contact_par_media")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Support_express")}</li>
                    <li><i className="fa-regular fa-circle-xmark"></i> {t("Acces_multi_utilisateurs")}</li>
                    <li><i className="fa-regular fa-circle-xmark"></i> {t("Statistiques_avancees")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Envoye_par_PressPilot")} </li>
                  </ul>
                  <br/><p className='tac'>{t("Prix")} : 19€ {t("by_mois")}</p>
                  
                  {userPremium ? (
                    <>
                      <br /><button className="ctaGreenTall actualPlan" onClick={gestionAbonnement}>{t("actual_plan")}</button>
                    </>
                  ) : (
                    <>
                      <br/><button className="ctaWhiteTall" onClick={handlePurchaseClick}>{t("Acheter")}</button>
                    </>
                  )}
                </div>
                <div className="itemPriceFeatured disabled">
                  <h3> {t("TGE")}</h3>
                  <ul>
                    <li><i className="fa-solid fa-circle-check"></i> 500 {t("credits_par_mois")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Acces_a_toutes_les_categories")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Plusieurs_contact_par_media")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Support_express")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Acces_multi_utilisateurs")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Statistiques_avancees")}</li>
                    <li><i className="fa-regular fa-circle-xmark"></i> {t("Envoye_par_PressPilot")} </li>
                  </ul>
                  <br/><p className='tac'>{t("Prix")} : 49€ {t("by_mois")}</p>
                  <br/><button className="ctaWhiteTall disabled">{t("Bientot")}</button>
                </div>
              </div>

              
              <br />
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Subscription;
