
import {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";

import { AuthContext } from '../../context/AuthContext.js';
import Loader from '../../components/Loader';

// LIBS
import { useTranslation } from "react-i18next";
import "../../i18n.js";

import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Steps } from 'antd';

// STYLES
import "./Release.css";


function StepsSteps({ etape }) {

  const { t, i18n } = useTranslation();

    return (
        <>
            <Steps
                current={etape}
                percent={60}
                items={[
                    {
                        title: t("step1"),
                    },
                    {
                        title: t("step2"),
                    },
                    {
                        title: t("step3"),
                    },
                    {
                        title: t("step4"),
                        // description,
                    }
                ]}
            /><br /><br />
        </>
    )


}

export default StepsSteps;