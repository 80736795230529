import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ReactDOM from "react-dom";
import GoogleConnect from "../components/GoogleConnect.js";

import {useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import {AuthContext} from "../context/AuthContext.js"

import "./modal.css";
import "../i18n";

const LoginModal = ({ isOpen, hide}) => {

  
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [info, setInfo] = useState('');
  const data = {email, setEmail, password, setPassword};
  const [forgetPassword, setForgetPassword] = useState(false);
  const dataFP = {email, setEmail};
  const hideForGGC = () => {
    hide();
  }

  const handleLogin = (e) => {
    e.preventDefault();
    fetch(process.env.REACT_APP_BASEURL + '/api/user/login', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        email,
        password
      })
    }).then(res => res.json()).then(res => {
      if(res.success){
        localStorage.setItem('Token', res.token);
        context.setUserInfos({
            email: res.email,
            nickname: res.nickname,
            firstname: res.firstname,
            surname: res.surname
        });
        context.setIsAuthenticate(true);
        context.setToken(res.token);
        if(res.isAdmin){
          context.setIsAdmin(true);
          hide();
          navigate('/admin');
        } else {
          hide();
          navigate('/dashboard');
        }
      }
      else {
        setError(res.msg);
        setTimeout(() => setError(''),5000);
      }
    });
  }
  const handleForgetPassword = (e) => {
    e.preventDefault();
    if(!localStorage.getItem('ForgetToken')){
      fetch(process.env.REACT_APP_BASEURL + '/api/user/forget-password', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          email: dataFP.email,
        })
      }).then(res => res.json()).then(res => {
        if(res.success){
          setInfo(res.msg);
          setTimeout(() => setInfo(''), 1500);
        }else{
          setInfo(res.msg);
        }
        if(res.token){
          localStorage.setItem('ForgetToken', res.token);
        }
      }).catch(console.error)
    }else{
      setInfo('Checked your mailbox')
    }
  }
  useEffect(() => {
    setForgetPassword(false);
    setEmail('');
    setInfo('');
  }, [isOpen])


  return (
  isOpen &&
    ReactDOM.createPortal(
          <div className="modalContainer">
              <div className="overlay" onClick={hide}></div>
              <div className="modal">
                <button type="button" className="modal-close-button" onClick={hide}>
                  <span className="closeBtn">&times;</span>
                </button>
                <div className="modalContent">
                    <div className="tac mt10 mb10">
                      <GoogleConnect label="Login with Google" methodeType="login" forHide={hideForGGC}/>
                    </div>
                  
                </div>

              </div>
          </div>,
        document.body
      )

  )}
export default LoginModal;
