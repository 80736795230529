import {useEffect, useState, useContext} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {AuthContext} from "../context/AuthContext.js";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";

import { Card, Col, Row } from 'antd';
import { Badge, Descriptions } from 'antd';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

import {
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Line } from 'react-chartjs-2';


// STYLES
import "./Rapport.css";


function Rapport() {

  ChartJS.register(ArcElement, Tooltip, Legend);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );


  const { t, i18n } = useTranslation();
  
  const context = useContext(AuthContext);
  const { idPressRelease } = useParams();

  const [countMedia, setCountMedia] = useState([]);
  const [object, setObject] = useState([]);
  const [sendAt, setSendAt] = useState([]);
  const [sentEmails, setSentEmails] = useState([]);
  
  const [opens, setOpens] = useState([]);
  const [sender, setSender] = useState([]);
  const [interestedCount, setInterestedCount] = useState([]);
  const [notInterestedCount, setNotInterestedCount] = useState([]);
  const [status, setStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dataChart = {
    labels: ["Taux d'ouverture", "Journaliste intéressé" , "Journaliste non interessé"],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const optionsLine = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
  };
  
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  const dataLine = {
    labels,
    datasets: [
      {
        label: "Taux d'ouverture",
        data: [20, 10, 60, 75, 88, 12, 35],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Ouvertures',
        data: [10, 30, 50, 90, 17, 66, 21],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  useEffect(() => {

    const fetchPressRelease = async () => {
      try {
        const token = localStorage.getItem('Token');
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
    
        const response = await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/${idPressRelease}`, {
          headers: new Headers(headers),
        });
    
        const json = await response.json();
        if (json.success) {
          const pressRelease = json.pressrelease;

          const sentEmails = pressRelease.to.filter(email => email.sent).length;

          setCountMedia(pressRelease.totalMedia);
          setObject(pressRelease.object);
          setSendAt(pressRelease.updatedAt);
          setOpens(pressRelease.opens);
          setSender(pressRelease.sender);
          setInterestedCount(pressRelease.interestedCount);
          setNotInterestedCount(pressRelease.notInterestedCount);
          setStatus(pressRelease.status);
          setSentEmails(sentEmails);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du communiqué de presse :', error);
      }
    };

    fetchPressRelease();
  }, []);

  // Exemple de données
  const emailsSent = countMedia;
  // const emailAlreadySent = emailSentAlready;
  const emailsOpened = opens;

  // Calcul du taux d'ouverture
  const openRate = (emailsOpened / emailsSent) * 100;

  if(isLoading){
    return(
      <Loader />
    )
  }

  if(context.isAuthenticate) {
    return (
      <>
      <div className="userDashboard">
        <div className="wrapConnect">
          <NavDashboard />
          <main id="UserDashboard">
            <h1>{t("Rapport_de_campagne")}</h1>
            <div className="">
              <div>
              <Row gutter={26}>
                <Col span={12} className="text16">
                  <span className="text16"><span className="bold"><i className="fa-solid fa-bullseye"></i> {t("Object_de_la_campagne")} :</span> {object}</span>
                </Col>
                <Col span={12} className="text16">
                  <span className="bold"><i className="fa-solid fa-signal"></i> {t("status_campagne")} :</span> <span> {(status == "sent") ? t("sent") : (status == "pending") ? t("pending") : (status == "pause") ? t("pause") : ""}</span>
                </Col>
                <Col span={12} className="text16">
                  <span className="bold"><i className="fa-solid fa-paper-plane"></i> {t("Delivree_le")} :</span> <span>{new Date(sendAt).toLocaleString()}</span>
                </Col>
                <Col span={12} className="text16">
                <span className="bold"><i className="fa-solid fa-user"></i> {t("Reponse_a")} :</span> <span>{sender}</span>
                </Col>
              </Row>
                {/* <span><span className="bold">{t("Object_de_la_campagne")} :</span> {object}</span>
                <br /><span className="bold">{t("status_campagne")} :</span> <span>{(status == "sent") ? t("sent") : (status == "pending") ? t("pending") : (status == "pause") ? t("pause") : ""}</span>
                <br /><span className="bold">{t("Delivree_le")} :</span> <span>{new Date(sendAt).toLocaleString()}</span>
                <br /><span className="bold">{t("Reponse_a")} :</span> <span>{sender}</span> */}
              </div><br/>
              <div>

              {/* <Row gutter={26}>
                  <Col span={8}>
                    <Card title={t("Object_de_la_campagne")} bordered={false}>
                      <h6>{object}</h6>
                    </Card>
                  </Col>
                  <Col span={5}>
                    <Card title={t("status_campagne")} bordered={false}>
                      <h6>{(status == "sent") ? t("sent") : (status == "pending") ? t("pending") : (status == "pause") ? t("pause") : ""}</h6>
                    </Card>
                  </Col>
                  <Col span={5}>
                    <Card title={t("Delivree_le")} bordered={false}>
                      <h6>{new Date(sendAt).toLocaleString()}</h6>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card title={t("Reponse_a")} bordered={false}>
                      <h6>{sender}</h6>
                    </Card>
                  </Col>
                </Row><br/> */}
                
                <Row gutter={26}>
                  <Col span={6}>
                    <Card title={t("destinataire")} bordered={false}>
                      <h4> {sentEmails} / {countMedia}</h4>
                      <p>{t("medias")}</p>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card title={t("Ouverture_estimees")} bordered={false}>
                      <h4>{opens}</h4>
                      <span className="green">{t("ouvertures")}</span>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card title={t("Taux_douvertures")} bordered={false}>
                      <h4>{openRate.toFixed(2)}</h4>
                     <p>%</p>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card title={t("Nombre_de_credits_depense")} bordered={false}>
                      <h4>{sentEmails}</h4>
                      <p>{t("credits")}</p>
                    </Card>
                  </Col>
                </Row><br/>

                <Row gutter={25}>
                  <Col span={12}>
                    <Card title={t("Interesse_pour_publier_un_article")} bordered={false}>
                      <h4>{interestedCount}</h4>
                      <p>{t("journalistes")}</p>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card title={t("Pas_interesse")} bordered={false}>
                      <h4>{notInterestedCount}</h4>
                      <p>{t("journalistes")}</p>
                    </Card>
                  </Col>
                </Row><br/>

                {
                  context.userInfos.isPremium ? (
                    <>

                    <Row gutter={25}>
                      <Col span={24}>
                        <Card title={<>{t("journalistes_qui_ont_dis_oui")} <i style={{color:"#F69331"}} className="fa-regular fa-gem"></i></>} bordered={false}>
                          <ul>
                            <li>{t("on_travaille_dessus")}</li>
                          </ul>
                        </Card>
                      </Col>
                    </Row><br/>
                    <Row gutter={25}>
                    <Col span={8} className="">
                      {/* <Card title={t("Pourcentage d'interessement")} bordered={false}>
                        <Pie data={dataChart} />
                      </Card> */}
                    </Col>
                    <Col span={16} className="">
                      {/* <Card title={t("Pas_interesse")} bordered={false}>
                        <Line options={optionsLine} data={dataLine} />
                      </Card> */}
                    </Col>
                  </Row>
                  </>

                  ) : (
                    <Row gutter={25}>
                      <Col span={8} className="premium">
                        <Card title={t("Interesse_pour_publier_un_article")} bordered={false}>
                          {/* <Pie data={dataChart} /> */}
                        </Card>
                      </Col>
                      <Col span={16} className="premium">
                        <Card title={t("Pas_interesse")} bordered={false}>
                          {/* <Line options={optionsLine} data={dataLine} /> */}
                        </Card>
                      </Col>
                    </Row>
                  )
                }
                
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
      </>
    );
  }
}

export default Rapport;
