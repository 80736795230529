import {useEffect, useState, useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";


import {AuthContext} from "../context/AuthContext.js";

import { Configuration, OpenAIApi } from "openai";
import { callApi } from '../components/chatgpt.js';

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";


// STYLES
import "./pressRelease.css";


function PressReleaseCreate() {
  
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);


  const generateRandomID = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 30;
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  const saveToDatabase = async (id) => {

  const status = "draft";
  
    const headers = { 'Content-Type': 'application/json' };
    if (context.token) {
      headers.Authorization = `Bearer ${context.token}`;
    } else if (localStorage.getItem('Token')) {
      headers.Authorization = `Bearer ${localStorage.getItem('Token')}`;
    }
    
    // Vérifiez si l'objet 'user' est défini avant d'accéder à sa propriété 'id'
    if (context.userInfos.id) {
      const body = JSON.stringify({ id, user: context.userInfos.id, status });
      const response = await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/create`, {
        method: 'POST',
        headers: new Headers(headers),
        body,
      });

      if (!response.ok) {
        throw new Error('Error saving ID to database');
      }
    } else {
      throw new Error('User not defined');
    }
};
  
const WriteByMySelf = async (status) => {
  const id = generateRandomID();
  try {
    await saveToDatabase(id, status);
    navigate(`/create-campaign/step1/${id}`);
  } catch (error) {
    console.error('Error:', error);
  }
};
  
  const WriteByGenerator = async (status) => {
    const id = generateRandomID();
    try {
      await saveToDatabase(id, status);
      navigate(`/create-campaign/step2/${id}`);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(()=>{
    //start to scrolltop 0
    window.scrollTo(0, 0);
      const fetchData = async () => {
        
        const headers = {'Content-Type': 'application/json'}
        if(context.token) {
          headers.Authorization = `Bearer ${context.token}`
        }else if(localStorage.getItem('Token')){
          headers.Authorization = `Bearer ${localStorage.getItem('Token')}`
        }
        const resProfile = await fetch(process.env.REACT_APP_BASEURL + '/api/user/profile',{headers: new Headers(headers)});
        const jsonProfile = await resProfile.json();
        
      }
      fetchData().catch(console.error);
      setIsLoading(false);
  },[]);


  if(isLoading){
    return(
      <Loader />
    )
  }



  const description = 'This is a description.';
  const descriptionstep = 'This is a description.';

  if(context.isAuthenticate) {
    
    return (
      <>
      <div className="userDashboard">
        <div className="wrapConnect">
          <NavDashboard />
          <main id="UserDashboard">
            <h1>{t("Creation_dun_communique_de_presse")}</h1>
            <p>{t("Choisissez_la_formule_qui_vous_convient_le_mieux")}</p>
            <br/>
              <div className="flexSpaceBetween">
                <button className="vignette" onClick={WriteByMySelf}><span className="iconVign">👀</span><br/><br/><h5>{t("Formule_solo")}</h5>{t("Formule_solo_description")}</button><br/><br/>
                <button className="vignette" onClick={WriteByGenerator}><span className="iconVign">🎉</span><br/><br/><h5>{t("Via_notre_IA")}</h5>{t("Via_notre_IA_description")}</button>

              <div>
            </div>
              
            </div>
          </main>
        </div>
      </div>
      <Footer />
      </>
    );
  }
}

export default PressReleaseCreate;
