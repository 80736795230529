import { useEffect, useState } from 'react';

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import "../i18n";

import Slider from "infinite-react-carousel";

// STYLES
import "./RoadMap.css";


  
function RoadMap(){

    const { t, i18n } = useTranslation();

    const[slideNum, setSlideNum] = useState();
    
    const getWindowSize = () => {
        let windowWidth = window.innerWidth;
        if(windowWidth > 1280) {
            setSlideNum(3);
        }
        else if (windowWidth < 1280 && windowWidth > 1024) {
            setSlideNum(2);
        }
        else if (windowWidth < 1024) {
            setSlideNum(1);
        }
    }
    
    useEffect(() => {
        getWindowSize();
        window.addEventListener('resize', getWindowSize);
        return () => {
            window.removeEventListener('resize', getWindowSize);
        }
    }, []);

    const settings =  {
        arrows: false,
        arrowsBlock: false,
        autoplay: false,
        duration: 500,
        adaptiveHeight: true,
        slidesPerRow: slideNum
    };
    
    return(
        <div className="RoadMapContainer">
            <Slider { ...settings }>
                <article className="preventSelect rm-element isDone">
                    <div className="contentWrap">
                        <p className="dateLabel mb10">Q3 2022</p>
                        <h4 className="mb10">{t('Launching Alpha')}</h4>
                        <p>
                            {t('Offer yourself a chance to be whitelisted which will gives an early access to the token sale. Hurry up, places are highly limited')}
                        </p>
                    </div>
                </article>
                <article className="preventSelect rm-element">
                    <div className="contentWrap">
                        <p className="dateLabel mb10">Q4 2022</p>
                        <h4 className="mb10">{t('Whitelist Closing')}</h4>
                        <p>
                            {t('Whitelist subscriptions will be closed and selected users will receive an exclusive NFT Airdrop before launching Beta')}
                        </p>
                    </div>
                </article>
                <article className="preventSelect rm-element">
                    <div className="contentWrap">
                        <p className="dateLabel mb10">Q4 2022</p>
                        <h4 className="mb10">{t('Airdrop (Alpha Badge)')}</h4>
                        <p>
                            {t('Drop of an NFT reserved to the registered on the alpha version of lootingg')}
                        </p>
                    </div>
                </article>
                <article className="preventSelect rm-element">
                    <div className="contentWrap">
                        <p className="dateLabel mb10">Q4 2022</p>
                        <h4 className="mb10">{t('Beta release')}</h4>
                        <p>
                            {t('Beta release allowing users to buy talent tokens with their crypto wallet + Credit card + First talent introductions on esport games + VIP Access')}
                        </p>
                    </div>
                </article>
                <article className="preventSelect rm-element">
                    <div className="contentWrap">
                        <p className="dateLabel mb10">Q2 2023</p>
                        <h4 className="mb10">{t('Add languages')}</h4>
                        <p>
                            {t('Added new languages to the platform such as Italian, Brazilian, Portuguese, German, Chinese and Korean')}
                        </p>
                    </div>
                </article>
                <article className="preventSelect rm-element">
                    <div className="contentWrap">
                        <p className="dateLabel mb10">Q2 2023</p>
                        <h4 className="mb10">{t('Invest in structures')}</h4>
                        <p>
                            {t('Introduction of esports organizations allowing to bet on them like an esport talent')}
                        </p>
                    </div>
                </article>
                <article className="preventSelect rm-element">
                    <div className="contentWrap">
                        <p className="dateLabel mb10">Q3 2023</p>
                        <h4 className="mb10">{t('Tournaments & Competitions')}</h4>
                        <p>
                            {t('Invest on results that teams could make during a tournament. Users could bet on the success of a direct talent in the manner of a sports bet')}
                        </p>
                    </div>
                </article>
                <article className="preventSelect rm-element">
                    <div className="contentWrap">
                        <p className="dateLabel mb10">Q4 2023</p>
                        <h4 className="mb10">{t('Future is coming')}</h4>
                        <p>
                            {t('Help us to build the future of esports and future of lootingg')}
                        </p>
                    </div>
                </article>
            </Slider>
        </div>
    )
}


export default RoadMap;