import React, { useState } from 'react';
import './Accordion.css';

const Accordion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
        <div className="accordion-question" onClick={toggleAccordion}>
            {question}
            <span className="accordion-indicator">{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && <div className="accordion-answer">{answer}</div>}
    </div>
  );
};

export default Accordion;
