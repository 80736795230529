import { useContext } from 'react';
import {Link, useNavigate, useLocation} from "react-router-dom";

import {AuthContext} from "../context/AuthContext.js"

import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Tour } from 'antd';
import { useRef, useState } from 'react';

import Tuto from './Tuto.js';

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import "../i18n";

// STYLES
import "./NavDashboard.css"

const NavDashboard = () => {

    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const context = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const disconnectUser = () => {
        context.disconnect()
    }


    return (
        <nav id="userProfileNav">
            
            <ul>
                {/* <Tuto ref1={ref1} ref2={ref2} /> */}
                <li className={location.pathname === '/dashboard' ? 'active-link' : ''}><Link to='/dashboard'><i className="fas fa-gauge"></i><span>{t('Dashboard')}</span></Link></li>
                <li className={location.pathname === '/press-release' ? 'active-link' : ''} ref={ref1}><Link to='/press-release'><i className="fa-regular fa-square-plus"></i><span>{t("Communique_de_presse")}</span></Link></li>
                {/* <li className={location.pathname === '/medias' ? 'active-link' : ''}><Link to='/medias'><i className="fa-regular fa-newspaper"></i><span>{t("Catalogue_Medias")}</span></Link></li> */}
                <li className="catNav"><span>{t("Parametres")}</span></li>
                <li className={location.pathname === '/profile' ? 'active-link' : ''} ref={ref2}><Link to='/profile'><i className="fas fa-user"></i><span>{t('Profile')}</span></Link></li>
                <li className={location.pathname === '/referral' ? 'active-link' : ''} ref={ref2}><Link to='/referral'><i className="fas fa-user-plus"></i><span>{t('Referral')}</span></Link></li>
                {/* <li className={location.pathname === '/history' ? 'active-link' : ''}><Link to='/history'><i className="fa-solid fa-clock-rotate-left"></i><span>{t("Historique")}</span></Link></li> */}
                <li className={location.pathname === '/sender' ? 'active-link' : ''}><Link to='/sender'><i className="fa-solid fa-seedling"></i><span>{t("Expediteur")}</span></Link></li>
                <li className={location.pathname === '/security' ? 'active-link' : ''}><Link to='/security'><i className="fas fa-shield-halved"></i><span>{t('Security')}</span></Link></li>
                <li className="catNav"><span>{t("Premium")}</span></li>
                <li className={location.pathname === '/subscription' ? 'active-link' : ''}><Link to='/subscription'><i className="fa-solid fa-credit-card"></i><span>{t("Subscription")}</span><span class="newPastille">{t("NEW")}</span></Link></li>
                <li className={location.pathname === '/credits' ? 'active-link' : ''}><Link to='/credits'><i className="fa-solid fa-coins"></i><span>{t("buy_credit")}</span></Link></li>
                {/* <li className="catNav"><span>Deconnexion</span></li> */}
                <li><a onClick={disconnectUser}><i className="fas fa-power-off"></i> <span>{t("Se_deconnecter")}</span></a></li>
            </ul>
        </nav>
    )
}
export default NavDashboard;