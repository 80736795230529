import "./Loader.css";

const Loader = () => (

      <div className="loaderContainer">
            <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
            <p>LOADING</p>
      </div>
)

export default Loader;
