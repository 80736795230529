import { useEffect, useState, useContext, useRef } from 'react';
import { Link as ScrollLink } from 'react-scroll';

import {Link, useNavigate} from "react-router-dom";

import {AuthContext} from "../context/AuthContext.js"
import GoogleConnect from "../components/GoogleConnect.js";

import LoginModal from "./loginModal";
import useModal from "./useModal";
import useScrollPosition from "./useScrollPosition";
import RegisterButton from './RegisterButton.js';

// import Tuto from './Tuto.js';

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import "../i18n";

import "./navBar.css";
import "./AdminSideBar.css"

const NavBar = () => {

  const ref3 = useRef(null);
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const [user, setUser] = useState();

  // if (context.userInfos) {
  //   console.log("context.userInfos.image", context.userInfos.image);
  // }

  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [openNavClass, setOpenNavClass] = useState('');

  const toggleNav = () => {
    setIsOpenNav(!isOpenNav);
  }
    
  const toggleModalLogin = () => {
    setIsOpenLogin(!isOpenLogin);
  }
  
  const hideForGGC = () => {
    hide();
  }

  // SET CLASS AFTER SCROLL
  const [isShrink, setIsShrink] = useState('');
  const currentScroll = useScrollPosition();
  
  useEffect(() => {
    // NAV OPEN CLASS
    if(isOpenNav) {
      setOpenNavClass('navOpen');
    }
    else {
      setOpenNavClass('');
    }
    // SHRINK NAV
    if(currentScroll > 50) {
      setIsShrink('shrink');
    }
    else {
      setIsShrink('');
    } 
  }, []);

  useEffect(() => {

    setUser(context.userInfos)
  }, [context.userInfos])

  window.addEventListener("scroll", function() {
    var navbar = document.getElementById('globalHeader');
    if (window.pageYOffset > 0) {
      navbar.classList.add('shrink');
    } else {
      navbar.classList.remove('shrink');
    }
  });
  

  
  if(user && user.id !== "") {

    if(context.isAdmin) {
      // ADMIN
        return (
          <>
          <nav id="AdminSideBar">
            <Link to='/admin'><img height="20" className="logo" src="/logo_white.png" alt="PressPilot Logo"/></Link>
            <ul>
              <li><Link to='/admin/users'><i className="fas fa-users"></i> Users</Link></li>
              <li><Link to='/admin/talents'><i className="fas fa-user-ninja"></i> Talents</Link></li>
              <li onClick={disconnectUser}><span><i className="fas fa-power-off"></i> Disconnect</span></li>
            </ul>
          </nav>
          </>
        )
      }
      // NOT ADMIN
      return (
        <nav id="globalHeader" className={isShrink +' '+ openNavClass}>
        {/* <Tuto ref3={ref3} /> */}
          <div className="mobileMenu" onClick={toggleNav}><span></span></div>
          <div className="navWrap wrapXL">
            <Link to='/' className="logo"><img height="50" className="logo" src="/logo_white.png" alt="PressPilot Logo"/></Link>
            <ul>
              {/* <li onClick={toggleNav}><Link to='/about'>{t("Learn more")}</Link></li> */}
              
              <li onClick={toggleNav} ><Link to='/profile'>{(user.image) ? <img className="avatar" src={user.image} /> : <i className="fas fa-user"></i>}{(user.agency.companyName) ? user.agency.companyName : user.nickname}</Link></li>
              <li onClick={toggleNav}><Link to='/subscription'><i className="fa-solid fa-coins"></i>{user.credits || 0} {t("credits")}</Link></li>
            </ul>
          </div>
        </nav>
      )  
    }
  
  // NOT LOGGED
    return (
      <>
      <LoginModal isOpen={isOpenLogin} hide={toggleModalLogin}/>
      <nav id="globalHeader" className={isShrink +' '+ openNavClass}>
        <div className="mobileMenu" onClick={toggleNav}><span></span></div>
          <div className="navWrap wrapXL">
          <Link to='/' className="logo"><img height="180" className="logoimg" src="/logo_white.png" alt="PressPilot Logo"/></Link>
            <ul>
              {/* <li onClick={toggleNav}><Link to='/register'>Comment ça fonctionne ?</Link></li> */}
              <li onClick={toggleNav}>
                <ScrollLink
                  to="HowItWorks"
                  smooth={true}
                  duration={500}
                  onClick={toggleNav}
                >
                  {t("Comment_ça_fonctionne")}
                </ScrollLink>
              </li>
              
              <li onClick={toggleNav}>
                <ScrollLink
                  to="FAQ"
                  smooth={true}
                  duration={500}
                  onClick={toggleNav}
                >
                  {t("FAQ")}
                </ScrollLink>
              </li>
              
              <li onClick={toggleNav}>
                <ScrollLink
                  to="Price"
                  smooth={true}
                  duration={500}
                  onClick={toggleNav}
                >
                  {t("Tarifs")}
                </ScrollLink>
              </li>
              
              <GoogleConnect className="cta" buttonText={t("Connexion_avec_Google")} label={t("Connexion_avec_Google")} methodeType="login" />
            </ul>
          </div>
        </nav>
      </>
    )

}

export default NavBar;