import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import NavDashboard from '../../components/NavDashboard';
import Loader from '../../components/Loader';
import Footer from '../../components/footer';

// LIBS
import { useTranslation } from "react-i18next";
import "../../i18n.js";

import Steps from './Steps.js';

// Editor components
import { CKEditor } from 'ckeditor4-react';

// Style
import './Editor.css';

function EditorRelease() {

  const { t, i18n } = useTranslation();

  const location = useLocation();
  const initialResponse = location.state?.data || '';
  const { idPressRelease } = useParams();
  const navigate = useNavigate();

  console.log("idPressRelease", idPressRelease);

  const [editorData, setEditorData] = useState(initialResponse);
  const [isLoading, setLoading] = useState(true);

  
  const handleEditorChange = (event) => {
    const newContent = event.editor.getData();
    setEditorData(newContent);
  };

  const fetchPressRelease = async () => {
    try {
      const headers = { 'Content-Type': 'application/json' };
      const token = localStorage.getItem('Token');
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
  
      const res = await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/${idPressRelease}`, {
        headers: new Headers(headers),
      });
  
      const json = await res.json();
      if (json.success) {
        setEditorData(json.pressrelease.content);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du communiqué de presse:", error);
    } finally {
      setLoading(false);
    }
  };

  const etape = 1;
  const step = "step4";
  const status = "draft";
  const updatePressReleaseContent = async (content, step, status) => {
    try {
      const token = localStorage.getItem('Token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

  
      const body = JSON.stringify({ content, step, status });
  
      await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/update/${idPressRelease}`, {
        method: 'PUT',
        headers: new Headers(headers),
        body,
      });
  
      console.log('Press release content and step4 updated successfully');
    } catch (error) {
      console.error('Error while updating press release content and step4:', error);
    }
  };

  useEffect(() => {
    fetchPressRelease();
  }, []);

  const NextStep = () => {
    updatePressReleaseContent(editorData, step, status);
    navigate(`/create-campaign/step4/${idPressRelease}`);
  };
  
  if(isLoading){
    return(
      <Loader />
    )
  }
  return (
    <>
      <div className="userDashboard">
        <div className="wrapConnect">
          <NavDashboard />
          <main id="Editor">
          <Steps etape={etape} />
            <h3 className="text-3xl font-bold dark:text-white">{t("Creer_votre_communique_editor")}</h3>
            <div className="flexSpaceBetween">
              <div className="editor">
                <CKEditor
                  name="PressPilot_Editor"
                  initData={editorData}
                  type="classic"
                  onChange={handleEditorChange}
                />
              </div>
            </div>
            <div className="right">
              <button onClick={NextStep} className="mt30 cta">{t("Suivant")}</button>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditorRelease;
