import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/Loader.js';

import { AuthContext } from '../../context/AuthContext.js';
import NavDashboard from '../../components/NavDashboard';
import Footer from '../../components/footer';

import moment from 'moment';
import Steps from './Steps.js';

// LIBS
import { useTranslation } from "react-i18next";
import "../../i18n.js";

// Notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Alert, Space } from 'antd';

// Style
import './Release.css';

function Step5() {
  const { t, i18n } = useTranslation();
  
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const { idPressRelease } = useParams();

  const [title, setTitle] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [Objectname, setObjectname] = useState("");
  const [description, setDescription] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [totalMediaCount, setTotalMediaCount] = useState(0);
  const [selectedMedias, setSelectedMedias] = useState(0);
  const [pressReleaseStatus, setPressReleaseStatus] = useState("");
  const [showSendingOptions, setShowSendingOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [scheduledDateTime, setScheduledDateTime] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 3000));

  const etape = 3;
  const step = "step5";

  const goBack = () => {
    navigate(`/create-campaign/step4/${idPressRelease}`);
  };
  
  const currentDateTime = moment().format();

  // Finir de mettre ca en place pour tester
  const handleDateTimeChange = (date, time) => {
    const selectedDateTime = moment(date + ' ' + time);
  
    if (selectedDateTime.isBefore(currentDateTime)) {
      // Affichez un message d'erreur indiquant que la date et l'heure sélectionnées sont dans le passé
    } else {
      setScheduledDateTime(selectedDateTime);
    }
  };

  const handleSendCampaign = async () => {
    const UserCredits = context.userInfos.credits;

    if (UserCredits >= selectedMedias) {

      if (selectedOption === "schedule" && scheduledDateTime) {
        
        const status = "scheduled";
          updatePressReleaseContent(step, Objectname, status, sender, scheduledDateTime);
          navigate(`/dashboard`);
        
      } else {
        // Envoyez la campagne immédiatement
        await sendCampaignNow(currentDateTime);
      }
    
    } else {
      toast.error(t("credits_insufisants"));
    }
  };  
  
  const sendCampaignNow = async (currentDateTime) => {
    const token = localStorage.getItem('Token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  
    const body = JSON.stringify({
      subject: Objectname,
      idPressRelease,
      sender: from,
    });
  
    // Affiche une notification toast
    toast.info(t("La_campagne_va_etre_envoyee_dans_les_prochaines_minutes"));
  
    // Met à jour le statut en "pending"
    updatePressReleaseContent(step, Objectname, "pending", sender, currentDateTime);
  
    // Redirige vers la page du tableau de bord
    navigate('/dashboard');
  
    const sendCampaignPromise = fetch(
      process.env.REACT_APP_BASEURL + `/api/pressrelease/sendcampaign`,
      {
        method: 'POST',
        headers: new Headers(headers),
        body,
      }
    );
  
    try {
      const response = await sendCampaignPromise;
      const json = await response.json();
  
      if (json.success) {
        console.log('Campaign sent successfully');
      } else {
        console.log('Error sending the campaign');
      }
    } catch (error) {
      console.error('Error while sending the campaign:', error);
    }
  };  

  const SendCampaign = async () => {

      if (Objectname) {
        setShowSendingOptions(true);
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        toast.error(t("Vous_devez_rentrer_un_objet_pour_votre_campagne"));
      }
    
  };

  const SendPreview = async () => {
    if (Objectname) {
      // Appeler la nouvelle API pour envoyer la prévisualisation de la campagne au sender
      const token = localStorage.getItem('Token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
  
      const body = JSON.stringify({
        subject: Objectname,
        idPressRelease,
        sender: from,
      });
  
      const sendPreviewPromise = fetch(
        process.env.REACT_APP_BASEURL + `/api/pressrelease/sendcampaignpreview`,
        {
          method: 'POST',
          headers: new Headers(headers),
          body,
        }
      );
  
      try {
        const response = await sendPreviewPromise;
  
        if (response.status === 200) {
          console.log('Preview sent successfully');
          toast.success(t("Email_envoyé_Pensez_a_regarder_dans_vos_spams"));
        } else {
          console.log('Error sending the preview');
        }
      } catch (error) {
        console.error('Error while sending the preview:', error);
      }
    } else {
      toast.error(t("Vous_devez_rentrer_un_objet_pour_votre_campagne"));
    }
  };
  

  const SaveStep = () => {
    const status = "draft";
    updatePressReleaseContent(step, Objectname, status, sender);
    navigate(`/dashboard`);
  };
  

  const updatePressReleaseContent = async (step, object, status, sender, currentDateTime) => {
    try {
      const token = localStorage.getItem('Token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };

      const body = JSON.stringify({ step, object, status, sender, currentDateTime });
  
      await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/update/${idPressRelease}`, {
        method: 'PUT',
        headers: new Headers(headers),
        body,
      });
  
      console.log('Press release content and step5 updated successfully');
    } catch (error) {
      console.error('Error while updating press release content and step5:', error);
    }
  };
  
  const getTotalMediaCount = () => {
    const total = selectedCategories.reduce((accumulator, category) => {
      const mediaCount = Number(category.mediaCount);
      return accumulator + (isNaN(mediaCount) ? 0 : mediaCount);
    }, 0);
    setTotalMediaCount(total);
  };

  // const recommandation = (selectedMedias) => {
  //   console.log("selectedMedias", selectedMedias);
  //   if(selectedMedias < 50) {
  //     return (
  //       <>
  //         <Alert
  //           message="Success Tips"
  //           description="Detailed description and advice about successful copywriting."
  //           type="success"
  //           showIcon
  //         />
  //       </>
  //     )
  //   }
  // }
  

  useEffect(() => {
    const fetchPressRelease = async () => {
      try {
        const token = localStorage.getItem('Token');
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
    
        const response = await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/${idPressRelease}`, {
          headers: new Headers(headers),
        });
    
        const json = await response.json();
        if (json.success) {
          const pressRelease = json.pressrelease;
          setSelectedCategories(pressRelease.to);
          setSelectedMedias(pressRelease.totalMedia);
          setObjectname(pressRelease.object);
          setPressReleaseStatus(pressRelease.status);
          setTitle(pressRelease.title);
          setIsLoading(false);
        }
        
      } catch (error) {
        console.error('Erreur lors de la récupération du communiqué de presse :', error);
      }
    };
  
    fetchPressRelease();
  }, []);

  useEffect(() => {
    getTotalMediaCount();
  }, [selectedCategories]);

  useEffect(() => {
    if (context.userInfos && context.userInfos.email) {
      setFrom(context.userInfos.email);
    }
  }, [context.userInfos]);
  
  const sender = from;

  const calculateSendingTime = () => {
    if (selectedMedias > 0) {
      return (selectedMedias - 1) * 2;
    } else {
      return 0;
    }
  };

  
  if(isLoading){
    return(
      <Loader />
    )
  }
  
  return (
    <>
      <div className="userDashboard">
        <div className="wrapConnect">
          <ToastContainer />
          <NavDashboard />
          {pressReleaseStatus === "sent" ? (
            <span>{t("La_campagne_deja_ete_envoye_impossible_de_la_modifier")}</span>
          ) : (
          <main id="Step4">
          <Steps etape={etape} />
          <h3 className="text-3xl font-bold dark:text-white">
            {t("Configuration_Envoi")}
          </h3>
          
          <br/>


          <div className="description">
                <label htmlFor="Objectname">{t("Objet_de_la_campagne")} * :</label>
                <input type="text" id="Objectname" placeholder={t("placeholder_object_campagne") + title} value={Objectname} onChange={(e) => setObjectname(e.target.value)} />
            </div>

          <div className="description">
            <label htmlFor="from">{t("Adresse_de_reponse")} :</label>
            <select
              id="from"
              required
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            >
              <option value={from}>{from}</option>
            </select>
          </div>

            <br/><br/><div className="description">
                <span className="italic">{t("Votre_communique_de_presse_va_etre_envoye_a")} {selectedMedias} {t("médias")}.</span>
            </div>

            {selectedMedias < 50 && 
              <Alert
                message={t("Success_Tips")}
                description={t("Afin_de_reussir_une_campagne_nous_vous_recommandons")}
                type="success"
                showIcon
              />
            }

            <div className="buttonDown">
                <div id="left">
                    <button onClick={goBack} className="mt30 cta">
                    <i className="fa-solid fa-arrow-left"></i> {t("Precedent")}
                    </button>
                </div>

                <div id="right">
                    <button onClick={SaveStep} className="mt30 cta"><i className="fa-solid fa-floppy-disk"></i> {t("Enregistrer_et_quitter")}</button>
                </div>
                <div id="right">
                    <button onClick={SendPreview} className="mt30 cta"><i className="fa-solid fa-paper-plane"></i> {t("Envoyer_un_test")}</button>
                </div>

                <div id="right">
                    <button onClick={SendCampaign} className="mt30 cta"><i className="fa-solid fa-paper-plane"></i> {t("Envoyer_la_campagne")}</button>
                </div>
            </div>
            <div className="OptionSend">
            {showSendingOptions && (
              <div className="sending-options">
                <h4>{t("Options_denvoi")}</h4>
                <div className="sending-options-item">
                  <input
                    type="radio"
                    id="send_now"
                    name="sending_option"
                    value="send_now"
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  <label htmlFor="send_now">{t("sent_now_1")} {calculateSendingTime()} {t("sent_now_2")}.)</label>
                </div>
                <div className="sending-options-item disabled">
                  <input
                    type="radio"
                    id="send_now"
                    name="sending_option"
                    value="send_now"
                    disabled
                  />
                  <label htmlFor="send_now disabled">{t("Envoyer_tout_immediatement")}</label>
                </div>
                <div className="sending-options-item">
                  <input
                    type="radio"
                    id="schedule"
                    name="sending_option"
                    value="schedule"
                    onChange={(e) => setSelectedOption(e.target.value)}
                  />
                  <label htmlFor="schedule">{t("Programmer_lenvoi")} (UTC+2) : </label>
                  {selectedOption === "schedule" && (
                    <input
                      type="datetime-local"
                      onChange={(e) => setScheduledDateTime(e.target.value)}
                    />
                  )}
                </div>
                <button onClick={handleSendCampaign} className="mt30 cta">
                  <i className="fa-solid fa-paper-plane"></i> {t("Confirmer_lenvoi")}
                </button>
              </div>
            )}
            </div>

          </main>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Step5;
