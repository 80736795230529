import { useEffect, useState, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext.js';

// LIBS
import { useTranslation } from "react-i18next";
import "../../i18n.js";
import NavDashboard from '../../components/NavDashboard';
import Loader from '../../components/Loader.js';
import Footer from '../../components/footer';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Col, Row } from 'antd';
import { Button, Drawer } from 'antd';
import Steps from './Steps.js';
import { Alert, Space } from 'antd';

import Modal from '../../components/modal.js';

// Style
import './Release.css';

function Step4() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const { idPressRelease } = useParams();

  const [mediaList, setMediaList] = useState([]);
  const [selectedMedias, setSelectedMedias] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [totalMediaCount, setTotalMediaCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState('fr'); // Défaut : français

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMediaList, setModalMediaList] = useState([]);

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const showDrawer = (categoryName) => {
    console.log("ici");
    console.log("categoryName", categoryName);
    SelectMedia(categoryName);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const filteredMediaList = mediaList.filter(media =>
    media.name.toLowerCase().includes(searchQuery.toLowerCase())
  );


  const etape = 2;
  const step = "step5"
  useEffect(() => {

    console.log("nombre media", selectedMedias);
    const fetchMedias = async () => {
      const headers = { 'Content-Type': 'application/json' };
      const token = localStorage.getItem('Token');
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
    
      try {
        const res = await fetch(process.env.REACT_APP_BASEURL + `/api/media/`, {
          headers: new Headers(headers),
        });
    
        const json = await res.json();
        if (json.success && json.media) {
          console.log("json.media", json.media);
          const groupedMedias = json.media.reduce((acc, media) => {
            if (media.category && media.category.length > 0) {
              media.category.forEach((category) => {
                if (category.name) {
                  if (!acc[category.name]) {
                    acc[category.name] = [];
                    console.log("ici");
                  }
                  acc[category.name].push(media);
                }
              });
            }
            return acc;
          }, {});
          console.log("groupedMedias", groupedMedias);
          setMediaList(groupedMedias);
    
          // Check if any media id from the mediaList is already present in selectedMedias state
          // If yes, then make it selected in the mediaList
          Object.entries(groupedMedias).forEach(([key, value]) => {
            value.forEach(media => {
              if (selectedMedias.find(selectedMedia => selectedMedia._id === media._id)) {
                media.selected = true;
              }
            })
          });
    
          setIsLoading(false);
        } else {
          console.error('Erreur: la réponse de l\'API ne contient pas de médias ou certains médias ne sont associés à aucune catégorie');
        }
    
      } catch (error) {
        console.error('Erreur lors de la récupération des médias :', error);
      }
    };    

    const fetchCategories = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BASEURL + '/api/category/count');
        const data = await response.json();
        setCategories(data.category);
        console.log("blabla cat", data.category);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const fetchPressRelease = async () => {
      try {
        const token = localStorage.getItem('Token');
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };
    
        const response = await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/${idPressRelease}`, {
          headers: new Headers(headers),
        });
    
        const json = await response.json();
        if (json.success) {
          const pressRelease = json.pressrelease;
          setSelectedCategories(pressRelease.to);
          console.log("nombre de médias:", pressRelease.totalMedia);
          setTotalMediaCount(pressRelease.totalMedia || 0);
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du communiqué de presse :', error);
      }
    };
    fetchCategories();
    fetchPressRelease();
    // fetchMedias();
  }, []);

  const handleViewAll = (categoryName) => {
    console.log("categoryName", categoryName);
    setIsModalOpen(categoryName);
  };
  

  useEffect(() => {
    if (categories.length > 0 && selectedCategories.length > 0) {
      const totalCount = selectedCategories.reduce((total, category) => {
        const matchingCategory = categories.find(cat => cat._id === category._id);
        if (matchingCategory) {
          return total + matchingCategory.mediaCount;
        } else {
          return total;
        }
      }, 0);
      setTotalMediaCount(totalCount);
    }
  }, [categories, selectedCategories]);
  

  const countSelectedMedias = (category) => {
    return selectedMedias.filter(media => media.category && media.category.some(cat => cat._id === category._id)).length;
  };  

  const goBack = () => {
    navigate(`/create-campaign/step3/${idPressRelease}`);
  };

  const NextStep = () => {
    if (selectedMedias.length > 0 || totalMediaCount > 0) {
      console.log("selectedMedias", selectedMedias);
      const selectedCategoryIds = selectedMedias.map((category) => category._id);
      let countToSend = 0;
      if (selectedMedias.length > 0) {
        countToSend = selectedMedias.length;
      } else if (totalMediaCount > 0) {
        countToSend = totalMediaCount;
      }
  
      // Utilisez `selectedMedias` au lieu de `selectedMedias.length`
      updatePressReleaseContent(step, selectedCategoryIds, selectedMedias);
      navigate(`/create-campaign/step5/${idPressRelease}`);
    } else {
      toast.error(t("Veuillez_selectionner_un_media"));
    }
  };
  

  const updatePressReleaseContent = async (step, selectedCategoryIds, selectedMedias) => {
    try {
      const token = localStorage.getItem('Token');
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
  
      // Transformez `selectedMedias` en un tableau d'identifiants de médias
      const mediaIds = selectedMedias.map(media => media._id);
  
      const body = JSON.stringify({
        step,
        to: selectedCategoryIds,
        selectedMedias: mediaIds.length,
      });
  
      await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/update/${idPressRelease}`, {
        method: 'PUT',
        headers: new Headers(headers),
        body,
      });
  
      console.log('Press release step5 updated successfully');
    } catch (error) {
      console.error('Error while updating press release step5:', error);
    }
  };  


  const handleSelectMedia = (media) => {
    if (selectedMedias.some(selectedMedia => selectedMedia._id === media._id)) {
      setSelectedMedias(selectedMedias.filter(selectedMedia => selectedMedia._id !== media._id));
    } else {
      setSelectedMedias([...selectedMedias, media]);
    }
  };

  const handleCategorySelection = (event, category) => {
    if (event.target.checked) {
      setSelectedCategories([...selectedCategories, category]);
      setTotalMediaCount(totalMediaCount + category.mediaCount);
    } else {
      setSelectedCategories(
        selectedCategories.filter((selected) => selected._id !== category._id)
      );
      setTotalMediaCount(totalMediaCount - category.mediaCount);
    }
  };

  const handleVignetteClick = (category) => {
    if (selectedCategories.some(selected => selected._id === category._id)) {
      setSelectedCategories(selectedCategories.filter(selected => selected._id !== category._id));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const SelectMedia = (categoryName) => {
    const fetchMediasData = async (categoryName) => {
      const headers = { 'Content-Type': 'application/json' };
      const token = localStorage.getItem('Token');
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
    
      try {
        const res = await fetch(process.env.REACT_APP_BASEURL + `/api/media/category/${categoryName}`, {
          headers: new Headers(headers),
        });
    
        const json = await res.json();
        if (json.success && json.media) {
          setMediaList(json.media);
        } else {
          console.error('Erreur: la réponse de l\'API ne contient pas de médias ou certains médias ne sont associés à aucune catégorie');
        }
        
      } catch (error) {
        console.error('Erreur lors de la récupération des médias :', error);
      }
    };

    if (categoryName) {
      fetchMediasData(categoryName);
    }
    
  }
  
  function capitalizeFirstLetter(text) {
    if (!text) {
      return '';
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  
  const handleSelectAll = () => {
    if (selectedMedias.length === mediaList.length) {
      setSelectedMedias([]);
    } else {
      // Créez un nouveau tableau qui contient tous les médias de `mediaList` qui ne sont pas déjà dans `selectedMedias`
      const newSelectedMedias = mediaList.filter(media => !selectedMedias.some(selectedMedia => selectedMedia._id === media._id));
      // Ajoutez tous les médias de `newSelectedMedias` à `selectedMedias`
      setSelectedMedias([...selectedMedias, ...newSelectedMedias]);
    }
  };

  function groupCategoriesByCountry(categories) {
    const groupedCategories = {};
  
    categories.forEach(category => {
      const country = category.country || "Autre"; // Si la catégorie n'a pas de pays, nous utilisons "Autre" comme valeur par défaut
      if (!groupedCategories[country]) {
        groupedCategories[country] = [];
      }
      groupedCategories[country].push(category);
    });
  
    return groupedCategories;
  }

  const groupedCategories = groupCategoriesByCountry(categories);

  function translateCountryToLanguage(country) {
    switch (country) {
      case 'France':
        return t("French");
      case 'USA':
        return t("English");
      default:
        return country; // Retourne le nom du pays tel quel s'il n'est pas trouvé dans les cas ci-dessus
    }
  }

  useEffect(() => {
    let totalCount = 0;
    Object.values(selectedMedias).forEach(media => {
        totalCount += media.length;
    });
    setTotalMediaCount(totalCount);
}, [selectedMedias]);

  if(isLoading){
    return(
      <Loader />
    )
  }

  return (
    <>
      <div className="userDashboard">
        <div className="wrapConnect">
          <NavDashboard />
          <main id="Step4">
          <Steps etape={etape} />
          <h3 className="text-3xl font-bold dark:text-white">
            {t("Sélectionnez_les_medias")}
          </h3>

          <Alert message={selectedMedias.length > 0 ? `${t("Vous_avez_selectionne")} : ${selectedMedias.length} ${t("medias")}.` : `${t("Vous_avez_selectionne")} : ${totalMediaCount} ${t("medias")}.`} type="info" />
            {/* <p>{t("Medias selectionnes")}: {selectedMedias.length}</p> */}
            <br/>
            <div className="categoryContainer" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px' }}>

        {Object.entries(groupedCategories).map(([country, categories]) => (
          <div key={country}>
            <h3>{country === "Autre" ? t("Other") : translateCountryToLanguage(country)}</h3>
              {categories.length ? (
                categories.map((category) => (
                <div key={category._id} id="mediaCat" className="vignetteMediaCategory" onClick={() => showDrawer(category.name)} >
                  <Row>
                    <Col span={7}>
                      <img className="i30" src={category.image} alt="Image Medias" />
                    </Col>
                    <Col span={15} style={{marginLeft:"10px"}}>
                      <h4>{capitalizeFirstLetter(category.name)}</h4>
                      <h6>{category.mediaCount} {t("medias")} - {category.country}</h6>
                      <span>{(category.description == "actualites_cat") ? t("actualites_cat") : category.description == "sport_cat" ? t("sport_cat") : category.description == "culture_car" ? t("culture_car") : category.description == "divertissement_cat" ? t("divertissement_cat") : category.description == "technologie_cat" ? t("technologie_cat") : category.description == "economie_cat" ? t("economie_cat") : category.description == "medias_cat" ? t("medias_cat") : category.description == "jeunesse_cat" ? t("jeunesse_cat") : category.description == "voyages_cat" ? t("voyages_cat") : category.description == "lifestyle_cat" ? t("lifestyle_cat") : category.description == "medias_inde" ? t("medias_inde") : t("Other")}</span>
                    </Col>
                    {/* <Col style={{ textAlign:"right" }} span={5}><button onClick={() => handleViewAll(category)} className="smallCta">Voir tout</button></Col> */}
                    {/* <Col style={{ textAlign:"right" }} span={5}><button onClick={() => showDrawer(category.name)} className="smallCta">Selectionner</button></Col> */}
                  </Row>
                  {selectedCategories.some(selected => selected._id === category._id) && <i className="fa fa-check-circle"></i>}
                </div>
              ))
              ) : (
                <div>
                    <Loader />
                </div>
              )}
              </div>
            ))}
            </div>

            <div className="buttonDown">
              <div id="left">
                <button onClick={goBack} className="mt30 cta">
                  <i className="fa-solid fa-arrow-left"></i> {t("Precedent")}
                </button>
              </div>

              <div id="right">
                <button onClick={NextStep} className="mt30 cta">
                  {t("Suivant")} <i className="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>

            {/* <Modal
              isOpen={isModalOpen}
              hide={() => setIsModalOpen(false)}
              categoryName={isModalOpen}
              selectedMedias={selectedMedias}
              setSelectedMedias={setSelectedMedias}
            /> */}

            <Drawer width={640} title="Choissiez vos médias" placement="right" onClose={onClose} open={open}>
            <Alert message={selectedMedias.length > 0 ? `${t("Vous_avez_selectionne")} : ${selectedMedias.length} ${t("medias")}.` : `${t("Vous_avez_selectionne")} : ${totalMediaCount} ${t("medias")}.`} type="info" /><br/>
              <Alert message={t("plusieurs_medias_meme_media")} type="warning" /><br/>
              <table id="drawerModal">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={mediaList.length && mediaList.length === selectedMedias.length}
                    />
                  </th>
                  <th>{t("Nom")}</th>
                  <th>{t("Credit")}</th>
                </tr>
              </thead>
              <tbody>
                {filteredMediaList.map((media, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        onChange={() => handleSelectMedia(media)}
                        checked={selectedMedias.some(selectedMedia => selectedMedia._id === media._id)}
                        />
                    </td>
                    <td>{media.name}</td>
                    <td>{media.credit}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </Drawer>

          </main>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Step4;
