import {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";

import { AuthContext } from '../../context/AuthContext.js';

import { Configuration, OpenAIApi } from "openai";
import { callApi } from '../../components/chatgpt.js';

// Notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// LIBS
import { useTranslation } from "react-i18next";
import "../../i18n.js";

import Footer from '../../components/footer.js';
import Loader from '../../components/Loader.js';
import NavDashboard from '../../components/NavDashboard.js';

import Steps from './Steps.js';

// STYLES
import "./Release.css";


function Step1() {
  
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const { idPressRelease } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isCreatingCommunique, setIsCreatingCommunique] = useState(false);

  const [description, setDescription] = useState("");
  const [response, setResponse] = useState("");
  const [keywords, setKeywords] = useState("");
  const [website, setWebsite] = useState("");
  const [title, setTitle] = useState("");
  const [error, setError] = useState(false);
  
  const etape = 0;
  const step = "step0";
  const status = "draft";

  async function createCommunique() {

    try {
        const step = "step3";
        // Mettre à jour le contenu du communiqué de presse dans la base de données
        await updatePressReleaseContent(idPressRelease, title, step, status);

        // Naviguer vers la nouvelle URL avec l'ID du communiqué de presse
        navigate(`/create-campaign/step3/${idPressRelease}`);
    } catch (error) {
        console.error('Error updating content:', error);
    }

    setIsLoading(true);
    setIsCreatingCommunique(false);
  }

  const updatePressReleaseContent = async (id, title, step, status) => {
    const headers = { 'Content-Type': 'application/json' };
    if (context.token) {
        headers.Authorization = `Bearer ${context.token}`;
    } else if (localStorage.getItem('Token')) {
        headers.Authorization = `Bearer ${localStorage.getItem('Token')}`;
    }

    const body = JSON.stringify({ title, step, status });
    const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pressrelease/update/${id}`,
        {
            method: 'PUT',
            headers: new Headers(headers),
            body,
        }
    );

    if (!response.ok) {
        throw new Error('Error updating content in database');
    }
};


  useEffect(()=>{
    //start to scrolltop 0
    window.scrollTo(0, 0);
      const fetchData = async () => {
        
        const headers = {'Content-Type': 'application/json'}
        if(context.token) {
          headers.Authorization = `Bearer ${context.token}`
        }else if(localStorage.getItem('Token')){
          headers.Authorization = `Bearer ${localStorage.getItem('Token')}`
        }
        const resProfile = await fetch(process.env.REACT_APP_BASEURL + '/api/user/profile',{headers: new Headers(headers)});
        const jsonProfile = await resProfile.json();
        
      }
      fetchData().catch(console.error);
      setIsLoading(false);
  },[]);

  if(isLoading){
    return(
      <Loader />
    )
  }

  if(context.isAuthenticate) {
    
    return (
      <>
      <div className="userDashboard">
        <div className="wrapConnect">
          <ToastContainer />
          <NavDashboard />
          <main id="UserDashboard">
          <Steps etape={etape} />
            <h1>{t("Creation_dun_communique_de_presse")}</h1>
            <div className="flexSpaceBetween">
            <div className="description">
              <label htmlFor="title">{t("Titre")}</label>
              <input type="text" id="title" placeholder={t("Placeholder_title")}
              
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            
            <div className="button">
            <button type="button" data-tooltip-target="tooltip-default" className={`smallCta red${isCreatingCommunique ? " disabled" : ""}`} onClick={createCommunique} disabled={isCreatingCommunique}>
              {t("Commencer_a_rediger_mon_communique_de_presse")}
            </button>

            </div>
              
            </div>
          </main>
        </div>
      </div>
      <Footer />
      </>
    );
  }
}

export default Step1;
