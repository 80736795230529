import {useEffect, useState, useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";

import {AuthContext} from "../context/AuthContext.js"

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import Checkbox from "../components/Checkbox.js";
import NavDashboard from "../components/NavDashboard.js";

import "./History.css";

import { useTranslation } from "react-i18next";
import "../i18n";


function History() {

const { t, i18n } = useTranslation();
  
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const [actionHistory, setActionHistory] = useState([]);

  useEffect(() => {
    if (context.isAuthenticate) {
      fetchActionHistory();
    }
  }, [context.isAuthenticate]);
  
  async function fetchActionHistory() {
    const response = await fetch(process.env.REACT_APP_BASEURL + `/api/history/${context.userInfos.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      },
    });
  
    const data = await response.json();
  
    if (data.success) {
      setActionHistory(data.actionHistory);
    } else {
      console.error("Erreur lors de la récupération de l'historique des actions");
    }
  
  }
  

  if(context.isAuthenticate) {
    return (
      <>
      <div className="userDashboard">
        <div className="wrapConnect">
          <NavDashboard />
          <main id="UserProfile">
            <h1>{t("Historique_des_transactions")}</h1>
            <table className="action-history">
              <thead>
                <tr>
                  <th>{t("Action")}</th>
                  <th>{t("Nombre_de_credits")}</th>
                  <th>{t("Date")}</th>
                </tr>
              </thead>
              <tbody>
                {actionHistory.map((action, index) => (
                  <tr key={index} className="action-history-item">
                    <td className="action-description">{action.details}</td>
                    <td className="action-credits">{action.creditsSpent} {t("credits")}</td>
                    <td className="action-date">{new Date(action.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </div>
      </div>
      <Footer />
      </>
    );
  }
}

export default History;
