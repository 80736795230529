import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext.js";

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";

// Notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// STYLES
import "./Referal.css";

function Referal() {
  const { t, i18n } = useTranslation();

  const context = useContext(AuthContext);

  const navigate = useNavigate();
  const [referralLink, setReferralLink] = useState('');
  const [userAffiliated, setUserAffiliated] = useState([]);

  // Function to generate the referral link
  useEffect(() => {
    if (context.isAuthenticate && context.userInfos.referal) {

      const link = window.location.origin + '/?ref=' + context.userInfos.referal;

      setReferralLink(link);

      axios.get(process.env.REACT_APP_BASEURL + '/api/user/profile', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${context.token}`
        }
      })
      .then(res => {
        if(res.data.success) {
          setUserAffiliated(res.data.userAffiliated);
        } else {
          // Gestion des erreurs
        }
      })
      .catch(error => {
        // Gestion des erreurs
      });

    }
  }, [context.isAuthenticate, context.userInfos]);

  // Function to copy referral link to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        toast.success(t("Copy_link_done"));
      })
      .catch((error) => {
        // Error feedback here
        console.log('Failed to copy referral link: ', error);
      });
  };

  if (context.isAuthenticate) {
    return (
      <>
        <div className="userDashboard">
          <div className="wrapConnect">

          <ToastContainer />
            <NavDashboard />
            <main id="UserDashboard">
              <h1>{t("Referal")}</h1>
              <div className="">
                {t("link_referral_give")}
                <br /><br />

                <fieldset>
                  <div>
                    <input
                      id="referralLink"
                      type="text"
                      required
                      value={referralLink}
                    />
                  </div>
                  <button className="cta" onClick={copyToClipboard}>{t("Copy_Link")}</button>
                </fieldset>
                <br />{t("Gagnez_credits_referral")}
              </div>
              <br />
              <h3>{t("Mes_filleuls")} :</h3>
              <ul>
                {userAffiliated.map((user, index) => (
                  <li key={index}><i className="fa-solid fa-arrow-right"></i> {user}</li>
                ))}
              </ul>
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Referal;
