import {Link} from "react-router-dom";

import "./NotFound.css"

// LIBS
import { useTranslation } from 'react-i18next';
import '../i18n';

function NotFound() {

  const { t, i18n } = useTranslation();

    return (
      <main id="mainNotFound">
        <div className="wrap tac vac">
          <h1 className="NotFound">404</h1>
          <p>{t("Page_not_found")}</p>
          <div><Link className="cta" to='/'>{t("Back_to_Homepage")}</Link></div>
        </div>
      </main>
    );
}

export default NotFound;
