import ReactDOM from "react-dom";
import {Fragment, useState} from "react";
import "../../components/modal.css";


const ModalCreateTalent = ({ isOpen, hide}) => {

  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');
  const [error, setError] = useState({});

  const [isLoading, setIsLoading] = useState(true);


  const data = {name, setName, nickname, setNickname, error};



  const handleCreateTalent = (e) => {
    e.preventDefault();
      fetch(process.env.REACT_APP_BASEURL + "/api/talent/create", {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({
          name,
          nickname,
        })
      }).then(res => res.json()).then(res => {
        if(res.success){
          hide();
        }
      });
  }


  return (
  isOpen &&
  ReactDOM.createPortal(
          <div className="modalContainer">
              <div className="overlay" onClick={hide}></div>
              <div className="modal">
                <div className="modalHeader">
                  <h4>Add new Talent</h4>
                  <button type="button" className="modal-close-button" onClick={hide}>
                    <span className="closeBtn">&times;</span>
                  </button>
                </div>
                <div className="modalContent">
                  <form onSubmit={handleCreateTalent}>
                        <input
                          id="name"
                          type="text"
                          placeholder="Name"
                          required
                          value={data.name}
                          onChange={(e)=>data.setName(e.target.value)}
                        />
                        <input
                          id="nickname"
                          type="text"
                          placeholder="Nickname"
                          required
                          value={data.nickname}
                          onChange={(e)=>data.setNickname(e.target.value)}
                        />
                    <div className="tac">
                      <button type="submit" className="cta">
                        <span>Create</span>
                      </button>
                    </div>
                  </form>
                </div>

              </div>
          </div>,
        document.body
      )
  )}
export default ModalCreateTalent;
