import { useEffect, useState, useContext } from 'react';
import {Link, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import LangModal from "../components/langModal.js";
import LanguageSelector from './LanguageSelector.js';
import CookieModal from './CookieModal.js';
import { hotjar } from 'react-hotjar';


import "./footer.css";

const Footer = () => {

  hotjar.initialize(3577767, 6);
  
  const { t, i18n } = useTranslation();
  const [isOpenLang, setIsOpenLang] = useState();


  const toggleModalLang = () => {
    setIsOpenLang(false);
    localStorage.setItem("isDefinedLang", true);
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("isDefinedLang", true);
  }

  useEffect(() => {
    // if token doesnt exist, open modal
    if(!localStorage.getItem('isDefinedLang')) {
      setIsOpenLang(true);
    }
    // if token exist, close modal
    else {
      setIsOpenLang(false);
    }
  }, [])

  
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/64b29a1f94cf5d49dc63c1a7/1h5cqk2ql';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();
    `;
    document.body.appendChild(script);
  }, []);


  return (
    <>
    {/* <LangModal isOpen={isOpenLang} hide={toggleModalLang}/> */}
    <CookieModal />
    <footer id="globalFooter">
        <div className="wrap tac">
            <img height="70" src="/logo_white.png" alt="PressPilot logo"/>
            <ul>
                <li><Link className="ReallysmallText" to="/journalist">{t("Vous_etes_journaliste")}</Link></li>
                <li><Link className="ReallysmallText" to="/legals">{t("Legals")}</Link></li>
                <li><Link className="ReallysmallText" to="/privacy">{t("Privacy")}</Link></li>
                <li>
                  <a onClick={() => changeLanguage('en-EN')}><img src="/flags/england.png" /></a>
                  <a onClick={() => changeLanguage('fr-FR')}><img src="/flags/france.png" /></a>
                  <a onClick={() => changeLanguage('es-ES')}><img src="/flags/spain.png" /></a>
                </li>
            </ul>
        </div>
        
        <div className="subFooter tac mt30">
            <p>{t("Copyright")} <a target="_blank" href="https://www.fibroweb.fr">{t("Fibroweb")}</a>{t("Copyright_bis")}<br /></p>
        </div>

    </footer>
    </>
  )


}
export default Footer;
