import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import React, { useState } from "react";
import translationEN from "./translate/en.json";
import translationFR from "./translate/fr.json";
import translationES from "./translate/es.json";
import translationPT from "./translate/pt.json";


let lang = navigator.language || navigator.userLanguage;
// IF USER CHOOSE LANGUAGE
if(localStorage.getItem('isDefinedLang')){
  lang = localStorage.getItem('i18nextLng');
}
else {
  lang = navigator.language || navigator.userLanguage;
}

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  es: {
    translation: translationES
  },
  pt: {
    translation: translationPT
  }
};


i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: lang, // Pour changer la langue
    // fallbackLng: 'en-EN',
    fallbackLng: ['en', 'fr', 'es', 'pt'], // use en if detected lng is not available
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });
  

export default i18n;