import {useState, useContext} from 'react';
import NavBar from "./components/navBar.js";
import HomePage from "./views/HomePage.js"
import RegisterPage from "./views/RegisterPage.js"
import Profile from "./views/Profile.js"
import Dashboard from './views/Dashboard.js'
import Security from "./views/Security"
import DashboardAdmin from "./views/DashboardAdmin.js"
import DashboardAdminUsers from "./views/admin/users.js"
import DashboardAdminTalents from "./views/admin/talents.js"
import NotFound from "./views/NotFound.js"
import PrivacyPage from "./views/PrivacyPage.js"
import LegalsPage from "./views/LegalsPage.js"
import About from './views/About.js';
import NewPress from './views/press-release.js';
import AccountValidation from "./views/AccountValidation.js"
import ChangePassword from "./views/ChangePassword.js"
import LanguageSelector from './components/LanguageSelector.js'
import Editor from './views/PressRelease/editor.js';
import Subscription from './views/Subscription.js';
import Medias from './views/Medias.js';
import MediasDetails from './views/Medias-details.js';
import Step1 from './views/PressRelease/Step1.js';
import Step2 from './views/PressRelease/Step2.js';
import Step3 from './views/PressRelease/editor.js';
import Step4 from './views/PressRelease/Step4.js';
import Step5 from './views/PressRelease/Step5.js';
import Sender from './views/Sender.js';
import Rapport from './views/Rapport.js';
import Journalist from './views/Journalist.js';
import Credits from './views/Credits.js';
import History from './views/History.js';
import Referal from './views/Referal.js';
//import Cursor from "./components/Cursor.js"

import {AuthContext} from "./context/AuthContext.js"
import AuthProvider from "./context/AuthContext.js"
import ReactGA from "react-ga4";

import {BrowserRouter, Routes, Route, Link, Navigate, useLocation} from "react-router-dom"

import { useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// Notifications 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';
import "./i18n";

ReactGA.initialize('G-93TP0NVFVV');
// ReactGA.pageview(window.location.pathname + window.location.search);


// PRIVATE USERS ROUTES
const PrivateRoute = ({children, isAuthenticated}) => {
  const context = useContext(AuthContext);
  return (
    context.isAuthenticate ? children : <Navigate to="/" />
  )
}
// PRIVATE ADMIN ROUTES
const PrivateAdminRoute = ({children, isAuthenticated}) => {
  const context = useContext(AuthContext);
  return (
    context.isAuthenticate && context.isAdmin ? children : <Navigate to={useLocation().pathname} />
  )
}

function App(){
  return(
    <BrowserRouter>
    <AuthProvider>
        <NavBar />
        <ToastContainer />
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/editor' element={<PrivateRoute><Editor /></PrivateRoute>} />
          <Route path='/history' element={<PrivateRoute><History /></PrivateRoute>} />
          <Route path='/press-release' element={<PrivateRoute><NewPress /></PrivateRoute>} />
          <Route path='/subscription' element={<PrivateRoute><Subscription /></PrivateRoute>} />
          <Route path='/credits' element={<PrivateRoute><Credits /></PrivateRoute>} />
          <Route path='/medias' element={<PrivateRoute><Medias /></PrivateRoute>} />
          <Route path='/medias/:mediaName' element={<PrivateRoute><MediasDetails /></PrivateRoute>} />
          <Route path='/create-campaign/step1/:idPressRelease' element={<PrivateRoute><Step1 /></PrivateRoute>} />
          <Route path='/create-campaign/step2/:idPressRelease' element={<PrivateRoute><Step2 /></PrivateRoute>} />
          <Route path='/create-campaign/step3/:idPressRelease' element={<PrivateRoute><Step3 /></PrivateRoute>} />
          <Route path='/create-campaign/step4/:idPressRelease' element={<PrivateRoute><Step4 /></PrivateRoute>} />
          <Route path='/create-campaign/step5/:idPressRelease' element={<PrivateRoute><Step5 /></PrivateRoute>} />
          <Route path='/rapport/:idPressRelease' element={<PrivateRoute><Rapport /></PrivateRoute>} />
          <Route path='/sender' element={<PrivateRoute><Sender /></PrivateRoute>} />
          <Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path='/referral' element={<PrivateRoute><Referal /></PrivateRoute>} />
          <Route path='/security' element={<PrivateRoute><Security /></PrivateRoute>} />
          <Route path='/admin' element={<PrivateAdminRoute><DashboardAdmin /></PrivateAdminRoute>} />
          <Route path='/admin/users' element={<PrivateAdminRoute><DashboardAdminUsers /></PrivateAdminRoute>} />
          <Route path='/admin/talents' element={<PrivateAdminRoute><DashboardAdminTalents /></PrivateAdminRoute>} />
          <Route path='*' element={<NotFound />} />
          <Route path='/privacy' element={<PrivacyPage />} />
          <Route path='/pr-newswire' element={<HomePage />} />
          <Route path='/legals' element={<LegalsPage />} />
          <Route path='/journalist' element={<Journalist />} />
        </Routes>
    </AuthProvider>
    </BrowserRouter>
  );
} // END APP


export default App;