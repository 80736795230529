import {useEffect, useState, useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";

import {AuthContext} from "../context/AuthContext.js"
import { useGoogleLogout } from "react-google-login"
import {gapi} from "gapi-script"

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import Checkbox from "../components/Checkbox.js";
import NavDashboard from "../components/NavDashboard.js";

import "./Sender.css";

import { useTranslation } from "react-i18next";
import "../i18n";


function Sender() {

const { t, i18n } = useTranslation();
  
  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [nickname, setNickName] = useState('');
  const [society, setSociety] = useState('');
  const [firstname, setFirstname] = useState('');
  const [address, setAddress] = useState('');
  const [tva, setTva] = useState('');
  const [surname, setSurname] = useState('');
  const [affiliateCode, setAffiliateCode] = useState('');
  const [newsLetter, setNewsLetter] = useState(false);
  const [selectedOption, setSelectedOption] = useState('1');
  const [referral, setReferral] = useState([]);
  const [error, setError] = useState({});
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const data = {
    email, setEmail, 
    nickname, setNickName,
    society, setSociety,
    firstname, setFirstname,
    address, setAddress,
    tva, setTva,
    surname, setSurname,
    newsLetter, setNewsLetter, 
    selectedOption, setSelectedOption, 
    affiliateCode, setAffiliateCode,
    referral, setReferral,
    error
  };

  const handleEditProfile = (e) => {
    e.preventDefault();
    const headers = {'Content-Type': 'application/json'}
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }else if(localStorage.getItem('Token')){
      headers.Authorization = `Bearer ${localStorage.getItem('Token')}`
    }
    // const gamesSelected = games.filter(games => games.isChecked === true);
    fetch(process.env.REACT_APP_BASEURL + '/api/user/update-profile', {
      headers: new Headers(headers),
      method: 'POST',
      body: JSON.stringify({
        email,
        nickname,
        firstname,
        surname,
        newsLetter,
        selectedOption,
      })
    }).then(res => res.json()).then(res => {
      if(res.success){
        setIsChanged(true);
        setTimeout(() => setIsChanged(false),2000);
        context.setUserInfos({
          email: email,
          nickname: nickname,
          firstname: firstname,
          surname: surname
        });
      }
    });
  };

  useEffect(()=>{
    //start to scrolltop 0
    window.scrollTo(0, 0);
      const fetchData = async () => {
        const headers = {'Content-Type': 'application/json'}
        if(context.token) {
          headers.Authorization = `Bearer ${context.token}`
        }else if(localStorage.getItem('Token')){
          headers.Authorization = `Bearer ${localStorage.getItem('Token')}`
        }
        const resProfile = await fetch(process.env.REACT_APP_BASEURL + '/api/user/profile',{headers: new Headers(headers)});
        const jsonProfile = await resProfile.json();
        setUser(jsonProfile.user);
        setEmail(jsonProfile.user.email)
        setNickName(jsonProfile.user.nickname)
        setFirstname(jsonProfile.user.firstname)
        setSurname(jsonProfile.user.surname)
      }
      fetchData().catch(console.error);
      setIsLoading(false);
  },[]);


  if(isLoading){
    return(
      <Loader />
    )
  }

  if(context.isAuthenticate) {
    return (
      <>
      <div className="userDashboard">
        <div className="wrapConnect">
          <NavDashboard />
          <main id="UserProfile">
            <h1>{t("Expediteur")}</h1>
            <p>{t("Selectionner_ci_dessous_expediteur")}. </p><br/>
            <form>
              <fieldset>
                <div>
                    <label className="labelUppercase">{t('Email')}</label>
                    <select
                        id="email"
                        required
                        value={data.email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                        <option value={data.email}>{data.email}</option>
                    </select>
                </div>

              </fieldset>
            </form>

            <div className="p30">
              <button type="submit" className="smallCta red disabled" disabled>
                <span>{t("Ajouter_un_nouvel_expediteur")} ({t("SOON")})</span>
              </button>
            </div>

            <div className="mt30 tar">
              <button onClick={handleEditProfile} type="submit" className="smallCta red">
                <span>{t("Confirm changes")}</span>
              </button>
            </div>
            {isChanged && 
              <div className="modalContainer msgModal">
                <div className="overlay"></div>
                <div className="modal tac">
                  <i className="fas fa-check"></i>
                  <p className="bold">{t("Account_Updated")}</p>
                </div>
              </div>
            }
          </main>
        </div>
      </div>
      <Footer />
      </>
    );
  }
}

export default Sender;
