import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';

export const AuthContext = createContext();

export default ({ children }) => {

  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthenticate, setIsAuthenticate] = useState(false);
  const [userInfos, setUserInfos] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('Token') || '');
  
  const updateCredits = (userId, credits) => {
    if (userInfos && userId === userInfos.id) {
      setUserInfos((prevUserInfos) => {
        return {
          ...prevUserInfos,
          credits: credits,
        };
      });
    }
  };  

  const disconnect = () => {
    setToken("")
    setIsAuthenticate(false)
    setUserInfos(null)
    setIsAdmin(false)
    navigate('/')
  }

  useEffect(() => {
    localStorage.setItem('Token', token);
    const headers = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
      fetch(process.env.REACT_APP_BASEURL + '/api/user/profile', {
        headers: new Headers(headers),
        method: 'GET',
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setIsAuthenticate(true)
            setUserInfos({
              id: res.user._id,
              nickname: res.user.nickname,
              firstname: res.user.firstname,
              surname: res.user.surname,
              credits: res.user.credits,
              email: res.user.email,
              image: res.user.image,
              onboarding: res.user.onboarding,
              referal: res.user.affiliateCode,
              isPremium: res.user.isPremium,
              premiumPlan: res.user.premiumPlan,
              agency: {
                companyName: res.user.agency.companyName,
                billingAddress: res.user.agency.billingAddress,
                vatNumber: res.user.agency.vatNumber,
              },
            });

            if(res.user.isAdmin) {
              navigate("/admin")
            } else {
              navigate("/dashboard")
            }
          }
        });
    }
  }, [token]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BASEURL);
  
    const handleCreditsUpdate = (data) => {
      updateCredits(data.userId, data.credits);
    };    
  
    socket.on('creditsUpdate', handleCreditsUpdate);
  
    return () => {
      socket.off('creditsUpdate', handleCreditsUpdate);
      socket.disconnect();
    };
  }, [userInfos]);

  useEffect(() => {
    if (localStorage.getItem('Token')) {
      setToken(localStorage.getItem('Token'));
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        token,
        setToken,
        isAdmin,
        setIsAdmin,
        userInfos,
        setUserInfos,
        disconnect,
        isAuthenticate
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
