import { useState, useEffect } from "react";
import axios from "axios";

import { useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import Footer from "../components/footer.js";


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./BasicPage.css";
import "../i18n";


function Journalist(){

    const { t, i18n } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [country, setCountry] = useState("");
    const [media, setMedia] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          await axios.post(process.env.REACT_APP_BASEURL + "/api/journalist/add", { name, email, country, media });

          toast.success(t("Vous_etes_inscrit"), {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

          setShowModal(false);
        } catch (error) {

          toast.error(t("Error"), {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
          console.error(error);
        }
    };

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return(
    <>
    <div className="globalContainer">
        <main id="journalistPage" className="BasicMain">
            <div className="wrap smallWrap">
                <h1>{t("Vous_etes_journaliste")}</h1>
                <div className="content">
                    <p>{t("Vous_souhaitez_apparaitre_dans_notre_annuaire")}</p>
                    <br/><h4>{t("Vous_souhaitez_rejoindre_le_cercle_des_medias_de_PressPilot")}</h4>
                    <button className="cta" onClick={() => setShowModal(true)}>{t("Cliquez_ici_pour_nous_rejoindre")}</button>
                    <br/><br/><h3>{t("Profitez_en_cest_gratuit")}</h3>
                    <p>{t("Recevez_en_avant_premiere_les_nouveautes_concernant_differentes_categories")}</p>
                    <br/><ul>
                        <li>{t("Journaux_nationaux")}</li>
                        <li>{t("Journaux_regionaux")}</li>
                        <li>{t("Journaux_economiques_et_financiers")}</li>
                        <li>{t("Journaux_sportifs")}</li>
                        <li>{t("Journaux_culturels_et_artistiques")}</li>
                        <li>{t("Journaux_scientifiques_et_technologiques")}</li>
                        <li>{t("Journaux_satiriques")}</li>
                        <li>{t("Journaux_destines_aux_jeunes")}</li>
                        <li>{t("Journaux_gratuits")}</li>
                        <li>{t("Blogs")}</li>
                        <li>{t("Radios")}</li>
                        <li>{t("Chaine_de_TV")}</li>
                        <li>{t("Journaux_professionnels_et_specialises")}</li>
                    </ul>
                </div>
            </div>
            {showModal && (
              <div className="modal">
                <div className="modal-content">
                  <button className="close" onClick={() => setShowModal(false)}><i className="fa-solid fa-circle-xmark"></i></button>
                  <h2>{t("Inscrivez_vous")}</h2>
                  <p>{t("admin_will_verify_informations")}</p><br/>
                  <form onSubmit={handleSubmit}>
                    <label htmlFor="name">{t("Nom")}:</label>
                    <input
                      type="text"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <label htmlFor="email">{t("Email")}:</label>
                    <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <label htmlFor="country">{t("Pays")}:</label>
                    <input
                      type="text"
                      id="country"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    />
                    <label htmlFor="media">{t("Média")}:</label>
                    <input
                      type="text"
                      id="media"
                      value={media}
                      onChange={(e) => setMedia(e.target.value)}
                      required
                    />
                    <button className="cta" type="submit">{t("sinscrire")}</button>
                  </form>
                </div>
              </div>
            )}
        </main>
        <Footer />
        </div>
    </>
    )
}


export default Journalist;
