import {useEffect, useState, useContext} from 'react';
import {Link} from "react-router-dom";
import Loader from "../components/Loader.js";
import {AuthContext} from "../context/AuthContext.js"
import "./AdminDashboard.css";
import "./Table.css";

function DashboardAdmin() {
  const context = useContext(AuthContext);
  const [users, setUsers] = useState();
  const [isLoading, setIsLoading] = useState(true);

  console.log("ok ici");

  useEffect(()=>{
    const headers = {'Content-Type': 'application/json'}
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }
    fetch(process.env.REACT_APP_BASEURL + '/api/user',{
      headers: new Headers(headers)
    })

    .then((res)=>
      res.json()
    )
    .then((res)=>{
      if(res.success){
        setUsers(res.users)
        setIsLoading(false)
      }
    })

  },[]);

  if(isLoading){
    return(
      <Loader />
    )
  }
  return (
    <main className="AdminDashboard" id="AdminDashboard">
      <div className="wrap vec">
        <h1>Welcome</h1>
        <ul>
          <li>
            <Link to='/admin/users'>
              <i className="fas fa-users"></i>
              <p>Users</p>
            </Link>
          </li>
          <li>
            <Link to='/admin/talents'>
              <i className="fas fa-user-ninja"></i>
              <p>Talents</p>
            </Link>
          </li>
        </ul>
      </div>
    </main>
  );
}

export default DashboardAdmin;
