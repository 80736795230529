import {useEffect, useState, useContext} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";

import { AuthContext } from '../../context/AuthContext.js';

import { Configuration, OpenAIApi } from "openai";
// import { callApi } from '../../components/chatgpt.js';

// Notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// LIBS
import { useTranslation } from "react-i18next";
import "../../i18n.js";

import Footer from '../../components/footer.js';
import Loader from '../../components/Loader.js';
import NavDashboard from '../../components/NavDashboard.js';

import Steps from './Steps.js';

// STYLES
import "./Release.css";

function Step2() {
  
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const { idPressRelease } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [isCreatingCommunique, setIsCreatingCommunique] = useState(false);

  const [description, setDescription] = useState("");
  const [response, setResponse] = useState("");
  const [keywords, setKeywords] = useState("");
  const [website, setWebsite] = useState("");
  const [language, setLanguage] = useState("French");
  const [title, setTitle] = useState("");
  const [error, setError] = useState(false);
  
  const etape = 0;
  const step = "step3";
  const status = "draft";

  async function createCommunique() {
    if (description) {
      // On vérifie que l'utilisateur a assez de crédits
      const userCredits = context.userInfos.credits;
      if (userCredits >= 5) {
        setIsCreatingCommunique(true);
  
        const headers = { 'Content-Type': 'application/json' };
        if (context.token) {
          headers.Authorization = `Bearer ${context.token}`;
        } else if (localStorage.getItem('Token')) {
          headers.Authorization = `Bearer ${localStorage.getItem('Token')}`;
        }
  
        const body = JSON.stringify({ description, keywords, website, language });
        console.log("language", language);
        const responsePromise = fetch(`${process.env.REACT_APP_BASEURL}/api/pressrelease/generatepresserelease`, {
          method: 'POST',
          headers: new Headers(headers),
          body,
        });
  
        // Utilisez toast.promise pour gérer les notifications
        const response = await toast.promise(responsePromise, {
          pending: t("Generation_du_communique"),
          success: t("Communique_generated_success"),
          error: t("Warning_generate_communique"),
        });
  
        if (response.ok) {
          const { content } = await response.json();
          // Mettre à jour le contenu du communiqué de presse dans la base de données
          await updatePressReleaseContent(idPressRelease, content, title, step);
  
          // Naviguer vers la nouvelle URL avec l'ID du communiqué de presse
          navigate(`/create-campaign/step3/${idPressRelease}`, {
            state: { data: content },
          });
        } else {
          toast.error(t("Warning_generate_communique"));
        }
  
        setIsCreatingCommunique(false);
      } else {
        // Afficher un message d'erreur et rediriger vers la page d'abonnement
        toast.error(t("credits_insufisants"));
        navigate("/subscription");
      }
    } else {
      toast.error(t("Il_faut_remplir_la_description"));
      setError(true);
    }
  }
  

  const updatePressReleaseContent = async (id, content, title, step, status) => {
    const headers = { 'Content-Type': 'application/json' };
    if (context.token) {
        headers.Authorization = `Bearer ${context.token}`;
    } else if (localStorage.getItem('Token')) {
        headers.Authorization = `Bearer ${localStorage.getItem('Token')}`;
    }

    const body = JSON.stringify({ content, title, step, status });
    const response = await fetch(
        `${process.env.REACT_APP_BASEURL}/api/pressrelease/update/${id}`,
        {
            method: 'PUT',
            headers: new Headers(headers),
            body,
        }
    );

    if (!response.ok) {
        throw new Error('Error updating content in database');
    }
};


  useEffect(()=>{
    //start to scrolltop 0
    window.scrollTo(0, 0);
      const fetchData = async () => {
        
        const headers = {'Content-Type': 'application/json'}
        if(context.token) {
          headers.Authorization = `Bearer ${context.token}`
        }else if(localStorage.getItem('Token')){
          headers.Authorization = `Bearer ${localStorage.getItem('Token')}`
        }
        const resProfile = await fetch(process.env.REACT_APP_BASEURL + '/api/user/profile',{headers: new Headers(headers)});
        const jsonProfile = await resProfile.json();
        
      }
      fetchData().catch(console.error);
      setIsLoading(false);
  },[]);


  if(isLoading){
    return(
      <Loader />
    )
  }

  if(context.isAuthenticate) {
    
    return (
      <>
      <div className="userDashboard">
        <div className="wrapConnect">
          <ToastContainer />
          <NavDashboard />
          <main id="UserDashboard">
          <Steps etape={etape} />
            <h1>{t("Creation_dun_communique_de_presse")}</h1>
            <div className="flexSpaceBetween">

            <div className="description">
              <label htmlFor="title">{t("Titre")} :</label>
              <input type="text" id="title" placeholder={t("Placeholder_title")}
              
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              />
            </div>
              
            <div className="description">
              <label htmlFor="description" className="label">{t("Description_complete_du_sujet")} * :</label>
              <textarea id="description" rows="4" className="textarea" placeholder={t("placeholder_description")}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              />  

            </div>

            <div className="description">
              <label htmlFor="keywords" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Keywords")} :</label>
              <input type="text" id="keywords" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("placeholder_keyworlds")} required
              
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              />
            </div>

            <div className="description">
              <label htmlFor="website" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Site_internet")} :</label>
              <input type="text" id="website" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="https://www.presspilot.io" required
              
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              />
            </div>

            <div className="description">
              <label htmlFor="language" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{t("Language")} :</label>
              <select
                id="language"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="French">{t("French")}</option>
                <option value="English">{t("English")}</option>
                <option value="Spanish" disabled>{t("Spanish")}</option>
              </select>
            </div>

            <div className="button">
            <button type="button" data-tooltip-target="tooltip-default" className={`smallCta red${isCreatingCommunique ? " disabled" : ""}`} onClick={createCommunique} disabled={isCreatingCommunique}>
              {t("Generer_le_communique_de_presse")} ( - 5 {t("credits")} )
            </button>

            </div>
              
            </div>
          </main>
        </div>
      </div>
      <Footer />
      </>
    );
  }
}

export default Step2;
