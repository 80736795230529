import React from 'react';
import {useEffect, useState, useContext, Component} from 'react';
import {Link, useNavigate} from "react-router-dom";

import { useTranslation } from "react-i18next";

import {AuthContext} from "../context/AuthContext.js"

// Last one 
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';

import jwtDecode from 'jwt-decode';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./GoogleConnect.css";

const lang = navigator.language || navigator.userLanguage;

const clientId = '741410305698-tufvav78a3llk11v3j2i4a45sdb83m2t.apps.googleusercontent.com';

let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
let passwordLength = 21;
let randomPass = "";
for (let i = 0; i <= passwordLength; i++) {
    let randomNumber = Math.floor(Math.random() * chars.length);
    randomPass += chars.substring(randomNumber, randomNumber +1);
}


const GoogleConnect = (props) => {
    const [error, setError] = useState('Error');
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const context = useContext(AuthContext);
    const { buttonText } = props;

    const onSuccess = response => {
        const token = response.access_token;
    
        // Utilisez le jeton d'accès pour faire une requête à l'API Google
        fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(profile => {
            const urlParams = new URLSearchParams(window.location.search);
            const referral = urlParams.get('ref');

            const requestUrl = process.env.REACT_APP_BASEURL + '/api/user/registergoogle';
    
            fetch(requestUrl, {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify({
                    email: profile.email,
                    nickname: profile.name,
                    firstname: profile.given_name,
                    surname: profile.family_name,
                    password: randomPass,
                    newsLetter: true,
                    language: lang,
                    selectedOption: "1",
                    image: profile.picture,
                    referral: referral
                })
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (res.success) {
                    const { companyName, billingAddress, vatNumber } = res.agency ? res.agency : { companyName: '', billingAddress: '', vatNumber: '' };
                    context.setUserInfos({
                        email: res.email,
                        nickname: res.nickname,
                        firstname: res.firstname,
                        surname: res.surname,
                        agency: {
                            companyName,
                            billingAddress,
                            vatNumber,
                        },
                    });
    
                    toast.success(t("Connexion_reussie"), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 2000, // Durée d'affichage en ms
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
    
                    context.setToken(res.token);
                }
                else {
                    setError({
                        confirmPassword: res.msg
                    });
                }
            });
        })
        .catch(error => {
            console.error("Erreur lors de la récupération du profil Google:", error);
        });
    };
    

    const onFailure = response => {
      console.log('FAILED', response);
    };
    
    const GoogleConnectButton = (props) => {
      const login = useGoogleLogin({
        onSuccess: onSuccess,
        onFailed: onFailure
      });

      return (
        <div className="googleConnectButton cta ctaGoogle">
          <button onClick={() => login()}>
          <svg className="iconGoogle" width="18" height="18" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fillRule="evenodd"><path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path><path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path><path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path><path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path><path fill="none" d="M0 0h18v18H0z"></path></g></svg>
           {buttonText}</button>
        </div>
      );
    };

    return(
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleConnectButton {...props} />
      </GoogleOAuthProvider>
    )

}


export default GoogleConnect;