// REACT STUFF
import {useEffect, useState, useContext, Component} from 'react';
import {Link, useNavigate} from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';

// AUTH CONTEXT
import {AuthContext} from "../context/AuthContext.js"

import GoogleConnect from '../components/GoogleConnect.js';

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import "../i18n";

//Components
import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import LoginModal from "../components/loginModal";
import RoadMap from "../components/RoadMap";
import RegisterButton from "../components/RegisterButton.js"
import FAQ from '../components/Faq.js';
import Accordion from '../components/Accordion.js';

import TypingText from '../components/typingText.js';

// LIB
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// CSS STYLES
import "./HomePage.css";

function HomePage(){

  const context = useContext(AuthContext);
  const [talents, setTalents] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [currentAvisIndex, setCurrentAvisIndex] = useState(0);

  const { t, i18n } = useTranslation();

  // console.log("t", t);
  // console.log("i18n", i18n);

  const toggleModalLogin = () => {
    setIsOpenLogin(!isOpenLogin);
  }


  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("success")) {
      toast.success(t("Credits_reçus"), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000, // Durée d'affichage en ms
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    }
  }, []);

  const avis = [
    {
      text: t("GuillaumeG_avis"),
      avatarSrc: "medias/users/GuillaumeG.jpg",
      name: "Guillaume G",
      position: "CEO - IDBucks"
    },
    {
      text: t("Tristan_berguer_avis"),
      avatarSrc: "medias/users/TristanB.jpeg",
      name: "Tristan B",
      position: "Marketing - Carimmat"
    }
  ];


  if(isLoading){
    return(
      <Loader />
    )
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentAvisIndex((currentAvisIndex + 1) % avis.length);
    }, 5000); // Change l'avis toutes les 5 secondes

    // Nettoyez l'intervalle lorsque le composant est démonté
    return () => clearInterval(interval);
  }, [currentAvisIndex, avis.length]);


  return(
    <>
    <LoginModal isOpen={isOpenLogin} hide={toggleModalLogin}/>
    
    <div className="globalContainer">
       <main id="homeMain">
         <div className="mainBg"><div></div><div></div><div></div><div></div><div></div></div>
         <div className="wrap vac">
          <div id="phoneOFF" className="mb30">
            <span style={{ fontWeight: "bold", marginRight:"10px"}}>{t("more_than_journalist")}</span>
            <span style={{ fontWeight: "100", marginRight:"20px", marginLeft:"10px"}}>|</span>
            <span>{t("More_than_1000")}</span>
          </div>
           <h1>{t("Pilotez_votre_entreprise")}<br /> {t("vers_le_succes_médiatique")}</h1>
           <br /><br />
           <div><img id="phoneOFF" className="line" src="/line.png" /></div>
           <div id="ButtonMobile">

            <button id="phoneOFF" className="productHuntButton">
              <a href="https://www.producthunt.com/posts/presspilot-2-0?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-presspilot&#0045;2&#0045;0" target="_blank">
                <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=408276&theme=light" alt="PressPilot - C" width="245" height="50" />
              </a>
            </button>
            
            <GoogleConnect className="cta" buttonText={t("register_for_free_now")} label="Login with Google" methodeType="login"/>
            <p className="noNeedCard"> {t("No_need_credit_card")}</p>
           </div>
           {/* <br/><p>{t("TypeHomePage")} <TypingText />...</p> */}
           

            {/* <div className="avisDisplay">
              {avis[currentAvisIndex].text}
              <br />
              <span>
                <img className="avatarRanking" src={avis[currentAvisIndex].avatarSrc} alt="Avatar" />
              </span>
              <span className="bold">{avis[currentAvisIndex].name}</span> - <span>{avis[currentAvisIndex].position}</span>
            </div> */}

         </div>
         <div className="mediaLogo">
          <img alt="Huawei" src="brands/huawei-logo-presspilot.png" />
          <img alt="Tommy Hilfiger" src="brands/tommy-hilfiger-logo-presspilot.png" />
          <img alt="Uber" src="brands/uber-logo-presspilot.png" />
          <img alt="Revolut" src="brands/revolut-logo-presspilot.png" />
          <img alt="Breevo, Sendinblue" src="brands/sendinblue-logo-presspilot.png" />
          {/* <img alt="Carimmat" src="brands/carimmat-logo-presspilot.png" /> */}
          <img alt="Uber Eats" src="brands/uber-eat-logo-presspilot.png" />
         </div>
       </main>
       <section id="infoWhatIsIt">
        <div className="wrap">
          <div className="tac mb30">
           <div><img className="downArrow" src="/down_arrow.png" /></div>
           <h2>{t("Diffusez_vos_communiques_de_presse")}<span> </span>{t("facilement_et_efficacement")}</h2>
           <p>{t("Rédigez_et_diffusez_vos_communiques_de_presse_facilement_en_quelques_clics_et")} <br/>{t("atteignez_votre_public_cible_en_toute_simplicite")}</p>
           <a className="colorPrimary" href="#NowGo">{t("Commencez_maintenant")} <i className="fa-solid fa-arrow-right"></i></a>
          </div>
        </div>
       </section>

       <section id="HowItWorks">
          <div className="wrap">
            <div className="tac mb30">
             <h2>{t("Comment_ca_fonctionne")}</h2>
            <img className="img" src="/line_h2.png" />
             <p>{t("Selectionnez_vos_catégories_et_medias_cibles_redigez_votre_communique_de_presse")}<br/> {t("et_diffusez_le_instantanement_grace_a_notre_plateforme_intuitive")}</p>
            </div>
          </div>
          <div className="wrap">
            <div className="mb30 vignetNumber p30">
              <img id="phoneOFF" className="curvedArrow" src="/curved_arrow.png" />
              <h4>{t("Redigez_votre_communique_de_presse")}</h4>
              <p>{t("Notre_editeur_de_texte_intuitif")}</p>
            </div>
            <div className="mb30 vignetNumber2 p30">
              <img id="phoneOFF" className="curvedArrowWay" src="/curved_way_arrow.png" />
              <h4>{t("Selectionnez_les_medias")}</h4>
              <p>{t("Choisissez_parmi_des_centaines_de_medias_dans_differentes")}</p>
            </div>
            <div className="mb30 vignetNumber p30">
              <h4>{t("Diffusez_le_en_quelques_clics")}</h4>
              <p>{t("Envoyez_vos_communiques_de_presse_instantanement_aux_medias_cibles")}</p>
            </div>
          </div>
        </section>

        <section id="Screen">
        <div className="wrap wrap-M">
          <ul>
            <li>
              <div className="content">
                <h5 className="feature feature-1">{t("Creation_assist_IA")}</h5>
                <h4>{t("Redigez_des_communiques_de_presse")}</h4>
                <p>{t("PressPilot_rend_la_redaction")}</p>
              </div>
              <img width={472} src="/app.png" alt="" />
            </li> 
            <li>
            <img width={472} src="/journalist.png" alt="" />
              <div className="content">
                <h5 className="feature feature-2">{t("Choissez_vos_medias")}</h5>
                <h4>{t("Atteignez_au_bon_moment")}</h4>
                <p>{t("vous_permet_de_choisir_parmi_des_centaines")}</p>
              </div>
            </li>
            <li>
              <div className="content">
                <h5 className="feature feature-1">{t("Suivez_en_temps_reel")}</h5>
                <h4>{t("suivez_la_performance_de_vos_communiques")}</h4>
                <p>{t("suivre_evolution_plateforme")}</p>
              </div>
              <img width={472} src="/suivi_data.png" alt="" />
            </li> 
          </ul>
        </div>
       </section>
        

        <section id="FAQ">
          <div className="wrap FAQ">
            <div className="tac mb30">
              <h2>{t("Questions_les_plus_frequemment_posees")}</h2>
              <img className="circle" src="/circle.png" />
              <p>
                {t("Decouvrez_toutes_les_reponses_a_vos_questions_les_plus_frequentes_sur")} <br /> {t("lutilisation_de_notre_plateforme")}
              </p>
            </div>
            <div className="accordionDiv">
              <Accordion
                question={t("Comment_fonctionne_PressPilot")}
                answer={t("PressPilot_est_une_plateforme_intuitive_qui_permet_de_creer_et_de_diffuser_rapidement_vos_communiques_de_presse")}
              />
              <Accordion
                question={t("Quels_types_de_medias_puis_je_toucher_avec_PressPilot")}
                answer={t("PressPilot_offre_une_large_selection_de_medias_dans_differentes_categories")}
              />
              <Accordion
                question={t("Est_ce_que_je_peux_rediger_mon_communique_de_presse_moi_meme")}
                answer={t("Oui_vous_pouvez_utiliser_lediteur_de_texte")}
              />
              <Accordion
                question={t("Combien_de_temps_faut_il_pour_diffuser_mon_communique_de_presse")}
                answer={t("Vous_pouvez_envoyer_vos_communiques_de_presse_instantanement_aux_medias_cibles")}
              />
              <Accordion
                question={t("Comment_savoir_si_mon_communique_de_presse_a_ete_diffuse_aux_medias_cibles")}
                answer={t("Vous_pouvez_suivre_en_temps_reel_la_diffusion_de_votre_communique_de_presse_sur_la_plateforme_PressPilot")}
              />
              <Accordion
                question={t("Est_ce_que_PressPilot_garantit_une_publication_de_mon_communique_de_presse")}
                answer={t("PressPilot_ne_garantit_pas_une_publication_de_votre_communique_de_presse_mais_nous_mettons")}
              />
              <Accordion
                question={t("Quelle_est_la_portee_de_diffusion_de_mes_communiques_de_presse")}
                answer={t("PressPilot_offre_une_large_selection_de_medias_dans_differentes_categories_vous_permettant_de_toucher")}
              />
              <Accordion
                question={t("Quels_pays_sont_concernés_par_presspilot")}
                answer={t("Quels_pays_sont_concernés_par_presspilot_answer")}
              />
            </div>
          </div>
        </section>
        

        <section id="Price">
           <div className="wrap">
             <div className="tac mb30">
              <h2>{t("Decouvrez_nos_tarifs")}</h2>
             <img className="sparkle" src="/sparkle.png" />
              <p>{t("Découvrez_nos_prestations_uniques_sans_filtres_vous_choisissez_ce_que_vous_voulez_pas_de_blabla")}<br/>{t("Payez_ce_que_vous_voulez")}</p>
              <div className="itemPrice">
                <h6>{t("A_la_demande")}</h6>
                <h3>0€</h3><span> {t("by_mois")}</span>
                <p>{t("Payez_uniquement_ce_dont_vous_avez_besoin_quand_vous_en_avez_besoin")}</p>
                <hr/>
                <ul>
                  <li><i className="fa-solid fa-circle-check"></i> {t("Pack_de_credits_a_la_demande")}</li>
                  <li><i className="fa-solid fa-circle-check"></i> {t("Acces_a_toutes_les_categories")}</li>
                  <li><i className="fa-solid fa-circle-check"></i> {t("Plusieurs_contact_par_media")}</li>
                  <li><i className="fa-solid fa-circle-check"></i> {t("Support_standard")}</li>
                  <li><i className="fa-regular fa-circle-xmark"></i> {t("Acces_multi_utilisateurs")}</li>
                  <li><i className="fa-regular fa-circle-xmark"></i> {t("Statistiques_avancees")}</li>
                  <li><i className="fa-solid fa-circle-check"></i> {t("Envoye_par_PressPilot")} </li>
                </ul>
              </div>
              
              <div className="itemPriceFeatured">
                <h6>{t("Entreprises")} <span className="newPastille">{t("NEW")}</span></h6>
                <h3>19€</h3><span> {t("by_mois")}</span>
                <p>{t("Benéficiez_de_credits_chaque_mois_pour_vos_envois_de_communique_de_presse")}</p>
                <hr/>
                  <ul>
                    <li><i className="fa-solid fa-circle-check"></i> 100 {t("credits_par_mois")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Acces_a_toutes_les_categories")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Plusieurs_contact_par_media")}</li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Support_express")}</li>
                    <li><i className="fa-regular fa-circle-xmark"></i> {t("Acces_multi_utilisateurs")}<span className="soonPastille">{t("Bientot")}</span></li>
                    <li><i className="fa-regular fa-circle-xmark"></i> {t("Statistiques_avancees")}<span className="soonPastille">{t("Bientot")}</span></li>
                    <li><i className="fa-solid fa-circle-check"></i> {t("Envoye_par_PressPilot")} </li>
                  </ul>
              </div>
              
              {/* <div className="itemPrice">
                <h6>Professionnels</h6>
                <h3>99€</h3><span> / mois</span>
                <p>Lorem ipsum dolor sit amet dolor siti conse ctetur adipiscing elit. </p>
                <hr/>
                <ul>
                  <li><i className="fa-solid fa-circle-check"></i> 1500 crédits par mois</li>
                  <li><i className="fa-solid fa-circle-check"></i> Accès à toutes les catégories</li>
                  <li><i className="fa-solid fa-circle-check"></i> Support express</li>
                  <li><i className="fa-solid fa-circle-check"></i> Accès multi-utilisateurs</li>
                  <li><i className="fa-solid fa-circle-check"></i> Statistiques avancées</li>
                  <li><i className="fa-regular fa-circle-xmark"></i> Envoyé par PressPilot </li>
                </ul>
              </div>  */}

             </div>
           </div>
         </section>

         
       <section id="NowGo">
          <div className="wrap">
            <div className="tac mb30">
             <p>{t("Vous_avez_un_projet_qui_merite_la_luimiere_des_medias")}</p>
             <h2>{t("Començons_ensemble_quelque_chose_de_magique")}</h2>
             <ScrollLink
                to="homeMain"
                smooth={true}
                duration={500}
              >
                <div className="ctaWhite">
                  {t("Rejoindre_laventure")}
                </div>
              </ScrollLink>
            </div>
          </div>
        </section>
       
      <Footer />
     </div>
    </>
  )
} // END HOMEPAGE



export default HomePage;
