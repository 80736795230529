import {useEffect, useState, useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";

import {AuthContext} from "../context/AuthContext.js";
import { TwitterTimelineEmbed, TwitterVideoEmbed } from 'react-twitter-embed';

import moment from "moment";
import "moment/locale/fr";

import { io } from 'socket.io-client';
const socket = io(process.env.REACT_APP_BASEURL);

// LIBS
import { useTranslation } from "react-i18next";
import "../i18n";

import { Button, Popconfirm, Space, Upload } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic } from 'antd';

import { Modal } from 'antd';

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";

// STYLES
import "./Dashboard.css";

function Dashboard() {
  
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);
  moment.locale("fr"); // Utiliser la locale française pour l'affichage des dates

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [newsLetter, setNewsLetter] = useState(false);
  const [selectedOption, setSelectedOption] = useState('1');
  const [error, setError] = useState({});
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [DataLoading, setDataLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [affiliateCode, setAffiliateCode] = useState('');
  const [affiliateCodeURL, setAffiliateCodeURL] = useState('');
  const [referral, setReferral] = useState([]);
  const data = {
    email, setEmail, 
    name, setName,
    firstname, setFirstname, 
    surname, setSurname,
    newsLetter, setNewsLetter,
    selectedOption, setSelectedOption, 
    affiliateCode, setAffiliateCode,
    affiliateCodeURL, setAffiliateCodeURL,
    referral, setReferral,
    error
  };
  const [pressReleases, setPressReleases] = useState([]);
  const [sentEmailsCount, setSentEmailsCount] = useState({});
  const [socketData, setSocketData] = useState({});
  const [journalistCount, setJournalistCount] = useState(0);
  const [prSent, setPrSent] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const getNmberSentPr = async () => {
    const headers = { 'Content-Type': 'application/json' };
    if (context.token) {
      headers.Authorization = `Bearer ${context.token}`;
    } else if (localStorage.getItem('Token')) {
      headers.Authorization = `Bearer ${localStorage.getItem('Token')}`;
    }
    const res = await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/sent/count`, {
      headers: new Headers(headers),
    });
    const json = await res.json();
    
    if (json.success) {
      setPrSent(json.count);
    }

  }

  const getNmberJournalist = async () => {
    const headers = { 'Content-Type': 'application/json' };
    if (context.token) {
      headers.Authorization = `Bearer ${context.token}`;
    } else if (localStorage.getItem('Token')) {
      headers.Authorization = `Bearer ${localStorage.getItem('Token')}`;
    }
    const res = await fetch(process.env.REACT_APP_BASEURL + `/api/media/countmedia`, {
      headers: new Headers(headers),
    });
    const json = await res.json();
    
    if (json.success) {
      setJournalistCount(json.journalistCount);
    }

  }

  const fetchPressReleases = async () => {
    const headers = { 'Content-Type': 'application/json' };
    if (context.token) {
      headers.Authorization = `Bearer ${context.token}`;
    } else if (localStorage.getItem('Token')) {
      headers.Authorization = `Bearer ${localStorage.getItem('Token')}`;
    }
    const res = await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/user/${context.userInfos.id}`, {
      headers: new Headers(headers),
    });
    const json = await res.json();
    
    // Créer un nouvel objet avec les champs dont vous avez besoin
    const pressReleasesWithTotalMedia = json.pressReleases.map((pressRelease) => {
      return {
        ...pressRelease,
        totalMedia: pressRelease.totalMedia,
        remainingEmails: pressRelease.totalMedia,
        pending: pressRelease.pending,
      };
    });
    setPressReleases(pressReleasesWithTotalMedia);
    setDataLoading(false);
  };
  

  const viewRapport = (idPressRelease) => {
    navigate(`/rapport/${idPressRelease}`);
  }

  const editPressRelease = (idPressRelease, step) => {
    if (step) {
      navigate(`/create-campaign/${step}/${idPressRelease}`);
    } else {
      console.error("Current step is not defined");
    }
  };

  const duplicatePressRelease = async (idPressRelease) => {
    const token = localStorage.getItem('Token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  
    try {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + `/api/pressrelease/duplicate/${idPressRelease}`,
        {
          method: 'POST',
          headers: new Headers(headers),
        }
      );
  
      const data = await response.json();
  
      if (data.success) {
        console.log('Press release duplicated successfully');
        // Add the duplicated press release to the local state
        setPressReleases([data.pressRelease, ...pressReleases]);
      } else {
        console.error('Error while duplicating press release:', data.msg);
      }
    } catch (error) {
      console.error('Error while duplicating press release:', error);
    }
  };
  

  const deletePressRelease = async (idPressRelease) => {
    const token = localStorage.getItem('Token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  
    try {
      const response = await fetch(process.env.REACT_APP_BASEURL + `/api/pressrelease/disable/${idPressRelease}`, {
        method: 'PUT',
        headers: new Headers(headers),
      });
  
      const data = await response.json();
  
      if (data.success) {
        console.log('Press release disabled successfully');
        // Remove the press release from the local state
        setPressReleases(pressReleases.filter(pr => pr.idPress !== idPressRelease));
      } else {
        console.error('Error while disabled press release:', data.msg);
      }
    } catch (error) {
      console.error('Error while disabled press release:', error);
    }
  };

  useEffect(() => {
    const checkWindowSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
  
    window.addEventListener('resize', checkWindowSize);
    checkWindowSize();
  
    return () => {
      window.removeEventListener('resize', checkWindowSize);
    };
  }, []);

  useEffect(()=>{
    //start to scrolltop 0
      const fetchData = async () => {
        const headers = {'Content-Type': 'application/json'}
        if(context.token) {
          headers.Authorization = `Bearer ${context.token}`
        }else if(localStorage.getItem('Token')){
          headers.Authorization = `Bearer ${localStorage.getItem('Token')}`
        }
        const resProfile = await fetch(process.env.REACT_APP_BASEURL + '/api/user/profile',{headers: new Headers(headers)});
        const jsonProfile = await resProfile.json();

        setUser(jsonProfile.user);
        // setOnboarding(jsonProfile.onboarding);
        setEmail(jsonProfile.user.email)
        setName(jsonProfile.user.nickname)
        setFirstname(jsonProfile.user.firstname)
        setSurname(jsonProfile.user.surname)
        fetchPressReleases().catch(console.error);
      }
      fetchData().catch(console.error);
      setIsLoading(false);
      getNmberJournalist();
      getNmberSentPr();
  },[]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("success")) {
      toast.success(t("Credits_reçus"), {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000, // Durée d'affichage en ms
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
    }
  }, []);


  function capitalizeFirstLetter(text) {
    if (!text) {
      return '';
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  useEffect(() => {
    socket.on("emailUpdate", (data) => {
      setSocketData(data);
  
      // Mettre à jour les emails restants pour la campagne correspondante
      setPressReleases((prevPressReleases) =>
        prevPressReleases.map((pressRelease) =>
          pressRelease.idPress === data.pressReleaseId
            ? { ...pressRelease, remainingEmails: data.remainingEmails }
            : pressRelease
        )
      );
    });
    return () => {
      socket.off("emailUpdate");
    };
  }, []);

  const disconnectUser = () => {
    context.disconnect()
}
  
  
  if(isLoading){
    return(
      <Loader />
    )
  }

  if(DataLoading){
    return(
      <Loader />
    )
  }

  if(context.isAuthenticate) {

    return (
      <>
      <div className="userDashboard">
      <div className="wrapConnect">
        {isMobile ? (
          <div className='tac mt30'>
            <i className="fa-6x fa-solid fa-laptop"></i><br/><br/>
            <span style={{ fontSize:"22px" }}>⚠️</span> <br/>{t("use_computer")}<br/>

            <br/><a className="cta mt30" onClick={disconnectUser}><i className="fas fa-power-off"></i> <span>{t("Se_deconnecter")}</span></a>
          </div>
        ) : (
          <>
          <NavDashboard />
          <main id="UserDashboard">
            <h1>{t("Bienvenue")} {context.userInfos.firstname} 👋</h1>
            <Row gutter={20}>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title={t("Nbr_journaliste_referencement")}
                    value={journalistCount}
                    valueStyle={{
                      color: '#3f8600',
                    }}
                    prefix={<ArrowUpOutlined />}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card bordered={false}>
                  <Statistic
                    title={t("Nbr_communique_sent_press")}
                    value={prSent}
                    valueStyle={{
                      color: '#3f8600',
                    }}
                    prefix={<ArrowUpOutlined />}
                  />
                </Card>
              </Col>
            </Row><br/>
            <div className="flexSpaceBetween">
              {pressReleases.filter((pressRelease) => pressRelease.disabled !== "true").length === 0 ? (
                <div>
                  <p>
                    {t("Aucune_campagne_en_cours")}
                  </p>
                </div>
              ) : (
                <table className="pressReleaseTable">
                <thead>
                  <tr>
                    <th className="first"></th>
                    <th className="first">{t('Title')}</th>
                    <th className="first">{t('Status')}</th>
                    <th>{t('Action')}</th>
                    {/* <th>{t('Delete')}</th> */}
                  </tr>
                </thead>
                <tbody>
                {pressReleases
                .filter((pressRelease) => pressRelease.disabled !== "true")
                .map((pressRelease) => {
                  const { idPress, step, title, status } = pressRelease;
                  return (
                    <tr key={idPress} id={idPress}>
                    <td className="first">
                    <span className={status ? status : "other"}>
                      <i className={`fa-solid ${
                        status === "sent"
                          ? "fa-paper-plane"
                          : status === "draft"
                          ? "fa-pen"
                          : status === "pending"
                          ? "fa-hourglass-start"
                          : status === "error"
                          ? "fa-triangle-exclamation"
                          : status === "scheduled"
                          ? "fa-solid fa-calendar"
                          : ""
                      }`}></i>
                    </span>
                    </td>
                    <td className="first">{capitalizeFirstLetter(title)}</td>
                    {/* Ici on affiche les emails restants */}
                    <td className="first">
                      {(status === "sent") ? capitalizeFirstLetter(t("sent")) : (status === "draft") ? capitalizeFirstLetter(t("draft")) : (status === "pending") ? capitalizeFirstLetter(t("pending")) : (status === "scheduled") ? capitalizeFirstLetter(t("scheduled")) : ""}
                      <br />
                      {pressRelease.sendCampaign
                        ? `${t("Campagne_envoyee_le")} ${moment(
                            pressRelease.sendCampaign
                          ).format("D MMM YYYY HH:mm")}`
                        : `${t("Derniere_modification_le")} ${moment(
                            pressRelease.updatedAt
                          ).format("D MMM YYYY HH:mm")}`}
                    </td>
                    <td>
                      {status === "sent" || status === "pending" || status === "error"  ? (
                        <button
                          className="viewButton"
                          onClick={() => viewRapport(idPress)}
                        >
                          <i className="fa-solid fa-chart-line"></i>
                        </button>
                      ) : (
                        <button
                          className="editButton"
                          onClick={() => editPressRelease(idPress, step)}
                        >
                          <i className="fa-solid fa-pen"></i>
                        </button>
                      )}
                    </td>
                    <td>
                      <button
                        className="copyButton"
                        onClick={() => duplicatePressRelease(idPress)}
                      >
                        <i className="fa-solid fa-copy"></i>
                      </button>
                    </td>
                    <td>
                      {status === "sent" || status === "pending" || status === "error" ? (
                        <span> </span>
                      ) : (
                        <Popconfirm
                        title={t("Etes_vous_sur_de_vouloir_supprimer_cette_campagne")}
                        okText={t("Yes")}
                        cancelText={t("No")}
                        onConfirm={() => deletePressRelease(idPress)} // Utilisez onConfirm ici
                      >
                        <button className="editButton">
                          <i className="fa-solid fa-delete-left"></i>
                        </button>
                      </Popconfirm>
                        
                      )}
                    </td>
                  </tr>
                  
                  );
                })}
                </tbody>
              </table>
              )}
            </div>
          </main>
          </>
        )}
        </div>
      </div>
      <Footer />
      </>
    );
  }
}

export default Dashboard;
