import {useEffect, useState} from 'react';

import Loader from "../../components/Loader.js";
import useModal from "../../components/useModal";
import ModalCreateTalent from "./modalCreateTalent";

import "../AdminDashboard.css";
import "./talents.css";


function DashboardAdminTalents() {

  const [talents, setTalents] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenCreateTalent, setIsOpenCreateTalent] = useState(false);

  const toggleCreateTalentModal = () => {
    setIsOpenCreateTalent(!isOpenCreateTalent);
  }

  useEffect(()=>{
    fetch(process.env.REACT_APP_BASEURL + "/api/talent")
    .then((res)=>
      res.json()
    )
    .then((res)=>{
      if(res.success){
        setTalents(res.talents)
        setIsLoading(false);
      }
    })
  },[]);
    if(isLoading){
      return(
        <Loader />
      )
    }
    return (
      <>
      <ModalCreateTalent isOpen={isOpenCreateTalent} hide={toggleCreateTalentModal} />
      <main className="AdminDashboard" id="DashboardAdminTalents">
        <div className="wrap vec">
          <header>
            <h1>Talents</h1>
            <button className="smallCta" onClick={toggleCreateTalentModal}>Create new</button>
          </header>
          <p className="results">{talents.length} result(s) found</p>
          <div className="articleWrap">
          {talents.map((talent, index)=>(
            <article key={index} className="adminTalent">
              <div className="picture" style={{ backgroundImage: "url(/favico.png)" }}></div>
              <h3>{talent.nickname}</h3>
              <p className="name">{talent.name}</p>
              <p className="id">ID : {talent._id}</p>
            </article>
          ))}
          </div>
        </div>
      </main>
      </>
    );
}

export default DashboardAdminTalents;
