import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

// LIBS
import { useTranslation } from 'react-i18next';
import '../i18n';

import Footer from '../components/footer.js';
import Loader from '../components/Loader.js';
import NavDashboard from '../components/NavDashboard.js';

import { Alert, Space } from 'antd';

// STYLES
import './MediasDetails.css';

function MediaDetails() {

  const { t, i18n } = useTranslation();
  const { mediaName } = useParams();
  const [mediaDetails, setMediaDetails] = useState(null);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchMediaDetails = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BASEURL + `/api/media/${mediaName}`);
        const data = await response.json();
        setMediaDetails(data.media);
      } catch (error) {
        console.error('Error fetching media details:', error);
      }
    };

    fetchMediaDetails();
  }, [mediaName]);


  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredMediaDetails = mediaDetails
  ? mediaDetails.filter((media) =>
      media.name.toLowerCase().includes(filter.toLowerCase())
    )
  : [];

  const uniqueMediaDetails = [...new Set(filteredMediaDetails.map(media => media.name))];

  return (
    <>
    <div className="userDashboard">
      <div className="wrapConnect">
        <NavDashboard />
        <main id="DetailsMedia">
          <h1>{t("more_details")}</h1>
          <div className="filter">
            <label htmlFor="filter">{t("Rechercher")}: </label>
            <input type="text" id="filter" value={filter} onChange={handleFilterChange} />
          </div>

          <Alert message={t("plusieurs_medias_meme_media")} type="warning" /><br/>


          <div className="mediaContainer">
              {uniqueMediaDetails ? (
                uniqueMediaDetails.map((name) => (
                <div className="mediaItem" key={name}>
                  {name}
                </div>
              ))
            ) : (
              <Loader />
            )}
          </div>
        </main>

      </div>
    </div>
    <Footer />
  </>
  );
}

export default MediaDetails;
