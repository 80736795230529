import {useEffect, useState, useContext} from 'react';
import {Link, useNavigate} from "react-router-dom";

import {AuthContext} from "../context/AuthContext.js"

import Footer from "../components/footer.js";
import Loader from "../components/Loader.js";
import NavDashboard from "../components/NavDashboard.js";

import "./SecurityPage.css";

import { useTranslation } from "react-i18next";
import "../i18n";


function Security() {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const context = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [newsLetter, setNewsLetter] = useState(false);
  const [selectedOption, setSelectedOption] = useState('1');
  const [error, setError] = useState({});
  const [user, setUser] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const data = {
    email, setEmail, 
    name, setName,
    firstname, setFirstname, 
    surname, setSurname,
    newsLetter, setNewsLetter, 
    selectedOption, setSelectedOption, 
    error
  };

  const disconnectUser = () => {
    context.setIsAuthenticate(false)
    context.setIsAdmin(false)
    localStorage.removeItem('Token')
    navigate('/')
  };
  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
  }

  const handleEditProfile = (e) => {
    e.preventDefault();
    const headers = {'Content-Type': 'application/json'}
    if(context.token) {
      headers.Authorization = `Bearer ${context.token}`
    }else if(localStorage.getItem('Token')){
      headers.Authorization = `Bearer ${localStorage.getItem('Token')}`
    }
    // const gamesSelected = games.filter(games => games.isChecked === true);
    fetch(process.env.REACT_APP_BASEURL + '/api/user/update-profile', {
      headers: new Headers(headers),
      method: 'POST',
      body: JSON.stringify({
        email,
        name,
        firstname,
        surname,
        newsLetter,
        selectedOption,
      })
    }).then(res => res.json()).then(res => {
      if(res.success){
        setIsChanged(true);
        setTimeout(setIsChanged(false),1000);

      }
    });
  };

  
const ButtonMailto = ({ mailto, label }) => {
  return (
      <Link
          to='#'
          onClick={(e) => {
              window.location.href = mailto;
              e.preventDefault();
          }}
      >
          {label}
      </Link>
  );
};

//   const handleCheckbox = (e) => {
//     const gamesTemp = [...games];
//     const gameFind = gamesTemp.find((game) => (
//       game._id === e.target.id
//     ))
//     const index = gamesTemp.indexOf(gameFind);
//     gamesTemp.splice(index, 1, {
//       _id:  e.target.id,
//       name: e.target.name,
//       value: e.target.value,
//       isChecked: e.target.checked
//     });
//     setGames([...gamesTemp]);
// }



  useEffect(()=>{
    //start to scrolltop 0
    window.scrollTo(0, 0);
      const fetchData = async () => {
        // const resGames = await fetch(process.env.REACT_APP_BASEURL + '/api/games');
        // const jsonGames = await resGames.json();
        const headers = {'Content-Type': 'application/json'}
        if(context.token) {
          headers.Authorization = `Bearer ${context.token}`
        }else if(localStorage.getItem('Token')){
          headers.Authorization = `Bearer ${localStorage.getItem('Token')}`
        }
        const resProfile = await fetch(process.env.REACT_APP_BASEURL + '/api/user/profile',{headers: new Headers(headers)});
        const jsonProfile = await resProfile.json();

        // for(let i = 0; i < jsonGames.games.length; i++){
        //   if(jsonProfile.user.games.find(game => game.name === jsonGames.games[i].name)){
        //     jsonGames.games[i].isChecked = true;
        //   }else{
        //     jsonGames.games[i].isChecked = false;
        //   }
        // }
        setUser(jsonProfile.user);
        setEmail(jsonProfile.user.email)
        setName(jsonProfile.user.nickname)
        setFirstname(jsonProfile.user.firstname)
        setSurname(jsonProfile.user.surname)
        setNewsLetter(jsonProfile.user.newsLetter)
        setSelectedOption(jsonProfile.user.selectedOption)
      }
      fetchData().catch(console.error);
      setIsLoading(false);
  },[]);


  if(isLoading){
    return(
      <Loader />
    )
  }

  if(context.isAuthenticate) {
     const infoChanged = () => {
      if(isChanged){
        return <span>{t("Change_validated")}</span>;
      }
    }
    return (
      <>
      <div className="userDashboard">
        <div className="wrapConnect">
          <NavDashboard />
          <main id="UserProfile">
            <h1>{t("Security")}</h1>
            <form>
              <fieldset>
                  <label className="labelUppercase">{t("Email")}</label>
                  <input
                    id="email"
                    type="email"
                    placeholder={context.userInfos.email}
                    disabled
                    value={data.email}
                  />
                  <p className="infoMsg">{t("You_cannot_change_your_email_address_at_this_time")}</p>
              </fieldset>
            </form>

            <div className="mt30">
              <label className="labelUppercase">{t("Account_deletion")}</label>
              <button className="ctaLink">
                  <ButtonMailto label={t("Request_account_deletion")} mailto="mailto:remove@presspilot.io" />
              </button>
            </div>

          </main>
        </div>
      </div>
      <Footer />
      </>
    );
  }
}

export default Security;
